import { JsonRpcProvider } from "@ethersproject/providers"
import { useEthers } from "@usedapp/core"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { useCallback, useEffect, useMemo, useState } from "react"

import { infuraRpcMap } from "config/base"

import { EthereumProviderKeyEnum } from "types/connection"

import { LocalStorageKey, useLocalStorage } from "../use-localstorage"

export function useWeb3Simple() {
  const [loading, setLoading] = useState<boolean>(false)

  // https://usedapp-docs.netlify.app/docs/API%20Reference/Hooks#useethers
  const { activateBrowserWallet, activate, account, deactivate, error, library: web3provider, chainId } = useEthers()
  const library = useMemo(() => {
    if (web3provider instanceof JsonRpcProvider && web3provider.getSigner) {
      return web3provider
    }
  }, [web3provider])

  const connected = !!account

  const [lastConnection, setLastConnection] = useLocalStorage<EthereumProviderKeyEnum | null>(
    LocalStorageKey.LAST_CONNECTION,
  )

  const connectBrowserWallet = useCallback(() => {
    setLoading(true)
    activateBrowserWallet()
    setLastConnection(EthereumProviderKeyEnum.METAMASK)
  }, [activateBrowserWallet])

  // https://usedapp-docs.netlify.app/docs/Guides/Connecting/Wallet%20Connect
  const connectWalletConnect = useCallback(async () => {
    setLoading(true)
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          ...infuraRpcMap,
        },
        qrcodeModalOptions: {
          mobileLinks: ["metamask", "trust"],
        },
      })
      await provider.enable()
      await activate(provider)
      provider.on("chainChanged", () => {
        window.location.reload()
      })

      setLastConnection(EthereumProviderKeyEnum.WALLET_CONNECT)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }, [activate])

  const disconnect = useCallback(async () => {
    deactivate()
    if (lastConnection === EthereumProviderKeyEnum.WALLET_CONNECT) {
      const provider = new WalletConnectProvider({
        rpc: {
          ...infuraRpcMap,
        },
      })
      await provider.disconnect()
    }
    setLastConnection(null)
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [error, connected])

  return {
    connect: connectBrowserWallet,
    connectWalletConnect,
    activate,
    disconnect,
    chainId,
    loading,
    error,
    connected,
    account: account?.toLowerCase(),
    library,
    walletType: lastConnection,
  }
}

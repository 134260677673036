import axios from "axios"

import type { Pool } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../utils"
import { getPool } from "./get-pool"

export type GetMyPoolResponseData = Array<Pool>

export const getMyPool = async (token: string) => {
  const res = await axios.get<GetMyPoolResponseData>(`${getApiBaseUrl()}/rent_pools/my`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  // TODO возвращает список моих пулов, но пока у нас может быть только 1 пул
  const data = returnIf200(res)

  if (data && data.length > 0) {
    return getPool(token, data[0].id)
  }
}

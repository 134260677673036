import type { Chain, Config } from "@usedapp/core"
import { DAppProvider, Mumbai, Polygon } from "@usedapp/core"
import { getDefaultProvider } from "ethers"
import type { NextPage } from "next"
import type { AppProps } from "next/app"
import type { GetServerSidePropsContext } from "next/types"
import React from "react"

import { infuraId, isPolygon } from "config/base"

import { CONNECTION_TYPE_KEY, WALLET_CONNECTION_TYPES } from "components/hooks/web3/use-wallet"

import { Layout } from "components/layout"
// import { DeveloperFlags } from "components/layout/developer-flags"
import { HtmlHead } from "components/layout/head"
import { CookiesPopupSpawn } from "components/modals/cookies"
import { WrongNetworkPopupSpawn } from "components/modals/wrong-network"

import { second } from "utils/time"

import "../styles/globals.scss"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
  noWeb3?: boolean
  requireNetwork?: boolean
  chain?: Chain
  isAutoConnectWallet?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: {
    autoConnect?: boolean
    authToken?: string
  }
}

const getConfig = (chain: Chain, autoConnect = false): Config => {
  let readOnlyUrls

  if (isPolygon) {
    readOnlyUrls = {
      [Polygon.chainId]: getDefaultProvider({
        chainId: Polygon.chainId,
        ensAddress: Polygon.multicallAddress,
        name: Polygon.chainName,
        _defaultProvider: providers => new providers.InfuraProvider("matic", infuraId),
      }),
    }
  } else {
    readOnlyUrls = {
      [Mumbai.chainId]: getDefaultProvider({
        chainId: Mumbai.chainId,
        ensAddress: Mumbai.multicallAddress,
        name: Mumbai.chainName,
        _defaultProvider: providers => new providers.InfuraProvider("maticmum", infuraId),
      }),
    }
  }
  return {
    readOnlyChainId: chain.chainId,
    readOnlyUrls: {
      // [Mainnet.chainId]: getDefaultProvider("mainnet"),
      ...readOnlyUrls,
    },
    pollingInterval: second * 5,
    autoConnect: autoConnect,
    refresh: 10,
  }
}

function MyApp(page: AppPropsWithLayout) {
  const chain = page.Component.chain ? page.Component.chain : isPolygon ? Polygon : Mumbai
  const getLayout = page.Component.getLayout ?? (page => <Layout>{page}</Layout>)
  const isAutoConnectWallet = page.Component.isAutoConnectWallet

  const showDisconnect = typeof window !== "undefined" ? window.location.pathname.includes("auth") : false
  //TODO move to new layout

  const wrongNetworkPopup = () => {
    if (typeof page.Component.requireNetwork === "boolean" && !page.Component.requireNetwork) {
      return null
    }
    if (page.Component.noWeb3) {
      return null
    }
    if (
      page.Component.getLayout ||
      (typeof page.Component.requireNetwork === "boolean" && page.Component.requireNetwork)
    ) {
      return <WrongNetworkPopupSpawn chain={chain} showDisconnect={showDisconnect} />
    }
    return null
  }

  return (
    <DAppProvider config={getConfig(chain, page.pageProps?.autoConnect || isAutoConnectWallet)}>
      <HtmlHead />
      <CookiesPopupSpawn />
      {wrongNetworkPopup()}
      {/*<DeveloperFlags />*/}
      {getLayout(<page.Component {...page.pageProps} />)}
    </DAppProvider>
  )
}

// Нужно для того, чтобы все страницы были динамикой (пока так) и переменные ENV прокидывались
MyApp.getInitialProps = async (page: { ctx: GetServerSidePropsContext }) => {
  return { autoConnect: page.ctx?.req?.cookies?.[CONNECTION_TYPE_KEY] === WALLET_CONNECTION_TYPES.EXTERNAL }
}

export default MyApp

import React from "react"
import { getIconSize } from "utils/get-icon-size"

export function MetaMask({
  h = 24,
  w = 24,
  className = "",
}: {
  h?: string | number
  w?: string | number
  className?: string
}) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-[${getIconSize(w)}] h-[${getIconSize(h)}] ${className}`}
    >
      <path
        d="M20.6486 2.67432L13.1533 8.24117L14.5394 4.9568L20.6486 2.67432Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3457 2.67395L10.7807 8.29354L9.46246 4.95644L3.3457 2.67395Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9513 15.5779L15.9551 18.6363L20.2263 19.8114L21.4541 15.6457L17.9513 15.5779Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.55371 15.6457L3.77405 19.8114L8.04524 18.6363L6.049 15.5779L2.55371 15.6457Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80446 10.4102L6.61426 12.2105L10.8553 12.3989L10.7047 7.84143L7.80446 10.4102Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1881 10.4103L13.2503 7.78882L13.1523 12.399L17.3859 12.2107L16.1881 10.4103Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04492 18.6362L10.5911 17.3933L8.39144 15.6758L8.04492 18.6362Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4014 17.3933L15.955 18.6362L15.601 15.6758L13.4014 17.3933Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9531 18.6365L13.3994 17.3936L13.6028 19.0583L13.5802 19.7589L15.9531 18.6365Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04395 18.6365L10.4168 19.7589L10.4018 19.0583L10.5901 17.3936L8.04395 18.6365Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4563 14.5761L8.33203 13.9509L9.83109 13.2654L10.4563 14.5761Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5381 14.5761L14.1633 13.2654L15.6699 13.9509L13.5381 14.5761Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04604 18.6364L8.40762 15.578L6.0498 15.6458L8.04604 18.6364Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5947 15.578L15.9563 18.6364L17.9525 15.6458L15.5947 15.578Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3878 12.2108L13.1543 12.3991L13.546 14.5762L14.1712 13.2654L15.6778 13.9509L17.3878 12.2108Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33275 13.9509L9.83934 13.2654L10.457 14.5762L10.8563 12.3991L6.61523 12.2108L8.33275 13.9509Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.61328 12.2108L8.39106 15.676L8.33079 13.9509L6.61328 12.2108Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6749 13.9509L15.5996 15.676L17.3849 12.2108L15.6749 13.9509Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8533 12.3992L10.4541 14.5762L10.9513 17.1449L11.0643 13.7626L10.8533 12.3992Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1516 12.3992L12.9482 13.7551L13.0386 17.1449L13.5433 14.5762L13.1516 12.3992Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5438 14.5762L13.0391 17.1449L13.4006 17.3935L15.6003 15.676L15.6756 13.9509L13.5438 14.5762Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33203 13.9509L8.3923 15.676L10.5919 17.3935L10.9535 17.1449L10.4563 14.5762L8.33203 13.9509Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5826 19.7588L13.6052 19.0582L13.4169 18.8925H10.577L10.4037 19.0582L10.4188 19.7588L8.0459 18.6364L8.87452 19.3143L10.5544 20.4819H13.4395L15.1269 19.3143L15.9555 18.6364L13.5826 19.7588Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4014 17.3934L13.0399 17.1448H10.9532L10.5916 17.3934L10.4033 19.0581L10.5766 18.8924H13.4165L13.6048 19.0581L13.4014 17.3934Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9635 8.60239L21.6038 5.52894L20.6471 2.67395L13.4004 8.05248L16.1876 10.4103L20.1273 11.5628L21.0011 10.5459L20.6245 10.2747L21.2271 9.7248L20.7601 9.36322L21.3627 8.90371L20.9635 8.60239Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.39453 5.52894L3.03483 8.60239L2.62805 8.90371L3.23069 9.36322L2.77118 9.7248L3.37382 10.2747L2.99717 10.5459L3.86346 11.5628L7.80319 10.4103L10.5904 8.05248L3.34368 2.67395L2.39453 5.52894Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1279 11.5629L16.1882 10.4104L17.3859 12.2108L15.6006 15.6759L17.9509 15.6458H21.4537L20.1279 11.5629Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80516 10.4104L3.86542 11.5629L2.55469 15.6458H6.04998L8.39273 15.6759L6.61495 12.2108L7.80516 10.4104Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1531 12.3992L13.4016 8.05271L14.5467 4.95667H9.46191L10.5919 8.05271L10.8555 12.3992L10.9459 13.7702L10.9534 17.145H13.0401L13.0551 13.7702L13.1531 12.3992Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0753296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React from "react"

export function InstagramSingleIcon({ fill = "white", h = 16, w = 16 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.2541 5.55879C6.76348 5.55879 5.56113 6.76113 5.56113 8.25176C5.56113 9.74238 6.76348 10.9447 8.2541 10.9447C9.74473 10.9447 10.9471 9.74238 10.9471 8.25176C10.9471 6.76113 9.74473 5.55879 8.2541 5.55879ZM8.2541 10.0025C7.29082 10.0025 6.50332 9.21738 6.50332 8.25176C6.50332 7.28613 7.28848 6.50098 8.2541 6.50098C9.21973 6.50098 10.0049 7.28613 10.0049 8.25176C10.0049 9.21738 9.21738 10.0025 8.2541 10.0025ZM11.6854 5.44863C11.6854 5.79785 11.4041 6.07676 11.0572 6.07676C10.708 6.07676 10.4291 5.79551 10.4291 5.44863C10.4291 5.10176 10.7104 4.82051 11.0572 4.82051C11.4041 4.82051 11.6854 5.10176 11.6854 5.44863ZM13.4689 6.08613C13.4291 5.24473 13.2369 4.49941 12.6205 3.88535C12.0064 3.27129 11.2611 3.0791 10.4197 3.03691C9.55254 2.9877 6.95332 2.9877 6.08613 3.03691C5.24707 3.07676 4.50176 3.26895 3.88535 3.88301C3.26895 4.49707 3.0791 5.24238 3.03691 6.08379C2.9877 6.95098 2.9877 9.55019 3.03691 10.4174C3.07676 11.2588 3.26895 12.0041 3.88535 12.6182C4.50176 13.2322 5.24473 13.4244 6.08613 13.4666C6.95332 13.5158 9.55254 13.5158 10.4197 13.4666C11.2611 13.4268 12.0064 13.2346 12.6205 12.6182C13.2346 12.0041 13.4268 11.2588 13.4689 10.4174C13.5182 9.55019 13.5182 6.95332 13.4689 6.08613ZM12.3486 11.3479C12.1658 11.8072 11.8119 12.1611 11.3502 12.3463C10.6588 12.6205 9.01816 12.5572 8.2541 12.5572C7.49004 12.5572 5.84707 12.6182 5.15801 12.3463C4.69863 12.1635 4.34473 11.8096 4.15957 11.3479C3.88535 10.6564 3.94863 9.01582 3.94863 8.25176C3.94863 7.4877 3.8877 5.84473 4.15957 5.15566C4.34238 4.69629 4.69629 4.34238 5.15801 4.15723C5.84941 3.88301 7.49004 3.94629 8.2541 3.94629C9.01816 3.94629 10.6611 3.88535 11.3502 4.15723C11.8096 4.34004 12.1635 4.69395 12.3486 5.15566C12.6229 5.84707 12.5596 7.4877 12.5596 8.25176C12.5596 9.01582 12.6229 10.6588 12.3486 11.3479Z"
        fill={fill}
      />
    </svg>
  )
}

import axios from "axios"

import type { PendingReward } from "types/reward"
import type { ReadyReward } from "types/reward"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type RewardForClaim = {
  id: string
  inserted_at: string
  rewards: ReadyReward[]
  status: "unclaimed"
  updated_at: string
}

export type GetRewardsResponseDataType = {
  pending: PendingReward[]
  pending_sum: Array<{ amount: number; type: "BIT" | "OIL" }>
  reward?: RewardForClaim
}

export const getRewards = async (token: string) => {
  const res = await axios.get<GetRewardsResponseDataType>(`${getApiBaseUrl()}/rewards`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

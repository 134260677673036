import React from "react"

export function Facebook({ fill = "white", h = 28, w = 28 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 22 22">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.231 0.230469C5.70811 0.230469 1.23096 4.70762 1.23096 10.2305C1.23096 15.7533 5.70811 20.2305 11.231 20.2305C16.7538 20.2305 21.231 15.7533 21.231 10.2305C21.231 4.70762 16.7538 0.230469 11.231 0.230469ZM12.2733 10.6697V16.1103H10.0222V10.6699H8.89762V8.7951H10.0222V7.66945C10.0222 6.13997 10.6573 5.23047 12.4615 5.23047H13.9635V7.10552H13.0246C12.3223 7.10552 12.2758 7.36753 12.2758 7.85651L12.2733 8.79489H13.9741L13.7751 10.6697H12.2733Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

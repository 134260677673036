import React from "react"

import styles from "./box.module.scss"

type AimType = "aim-l" | "aim-r" | "aim-full"

export type BoxProps = {
  className?: string
  theme?: "default" | AimType
  relative?: boolean
  row?: boolean
  full?: boolean
  center?: boolean
  children?: React.ReactNode | null
  as?: "div" | "section"
  style?: React.CSSProperties
  onClick?: () => void
}

export function Box({
  children,
  row,
  full,
  center,
  className = "",
  theme = "default",
  relative = false,
  as = "div",
  style,
  onClick,
}: BoxProps) {
  const classes = [className, "flex"]
  if (center) {
    classes.push("justify-center items-center")
  }
  if (!row) {
    classes.push("flex-col")
  }

  if (full) {
    classes.push("w-full h-full")
  }

  if (onClick) {
    classes.push("cursor-pointer")
  }

  if (theme.indexOf("aim") !== -1) {
    classes.push("relative")
    return (
      <div className={relative ? "p-6" : ""}>
        <div style={style} className={classes.join(" ")}>
          {children}
          {renderAimTheme(theme as AimType)}
        </div>
      </div>
    )
  }
  const Tag = as
  return (
    <Tag style={style} className={classes.join(" ")} onClick={onClick}>
      {children}
    </Tag>
  )
}

const renderAimTheme = (theme: AimType) => {
  switch (theme) {
    case "aim-l":
      return (
        <>
          <div className={styles.aim__right_bottom} />
          <div className={styles.aim__left_top} />
        </>
      )
    case "aim-r":
      return (
        <>
          <div className={styles.aim__left_bottom} />
          <div className={styles.aim__right_top} />
        </>
      )
    case "aim-full":
      return (
        <>
          <div className={styles.aim__left_bottom} />
          <div className={styles.aim__right_top} />
          <div className={styles.aim__right_bottom} />
          <div className={styles.aim__left_top} />
        </>
      )
    default:
      return null
  }
}

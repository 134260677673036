import React from "react"

export function Rotating({
  fill = "white",
  h,
  w,
  className = "",
}: {
  fill?: string
  h?: number | string
  w?: number | string
  className?: string
}) {
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0331 4.68563C11.9341 3.65653 10.5078 3.09241 8.99466 3.09375C6.27152 3.09614 3.92062 4.96329 3.27154 7.55332C3.22429 7.74186 3.05631 7.875 2.86193 7.875H0.847371C0.58377 7.875 0.38352 7.63569 0.432281 7.37663C1.19296 3.33717 4.73952 0.28125 9 0.28125C11.3361 0.28125 13.4575 1.20009 15.0228 2.69596L16.2784 1.44035C16.8099 0.908824 17.7188 1.28528 17.7188 2.03699V6.75C17.7188 7.216 17.341 7.59375 16.875 7.59375H12.162C11.4103 7.59375 11.0338 6.68493 11.5654 6.15336L13.0331 4.68563ZM1.125 10.4062H5.83801C6.58972 10.4062 6.96618 11.3151 6.43465 11.8466L4.96688 13.3144C6.06593 14.3435 7.49232 14.9077 9.00548 14.9063C11.7272 14.9038 14.0791 13.0379 14.7285 10.4468C14.7757 10.2582 14.9437 10.1251 15.1381 10.1251H17.1527C17.4163 10.1251 17.6165 10.3644 17.5678 10.6234C16.807 14.6628 13.2605 17.7188 9 17.7188C6.66394 17.7188 4.5425 16.7999 2.97721 15.304L1.7216 16.5596C1.19007 17.0912 0.28125 16.7147 0.28125 15.963V11.25C0.28125 10.784 0.659004 10.4062 1.125 10.4062Z"
        fill={fill}
      />
    </svg>
  )
}

import React from "react"

export function Menu({ fill = "white", h = 20, w = 20 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 1.9998C0.600098 1.68154 0.726526 1.37632 0.951569 1.15128C1.17661 0.926233 1.48184 0.799805 1.8001 0.799805H16.2001C16.5184 0.799805 16.8236 0.926233 17.0486 1.15128C17.2737 1.37632 17.4001 1.68154 17.4001 1.9998C17.4001 2.31806 17.2737 2.62329 17.0486 2.84833C16.8236 3.07338 16.5184 3.1998 16.2001 3.1998H1.8001C1.48184 3.1998 1.17661 3.07338 0.951569 2.84833C0.726526 2.62329 0.600098 2.31806 0.600098 1.9998ZM0.600098 7.9998C0.600098 7.68155 0.726526 7.37632 0.951569 7.15128C1.17661 6.92623 1.48184 6.7998 1.8001 6.7998H16.2001C16.5184 6.7998 16.8236 6.92623 17.0486 7.15128C17.2737 7.37632 17.4001 7.68155 17.4001 7.9998C17.4001 8.31806 17.2737 8.62329 17.0486 8.84833C16.8236 9.07338 16.5184 9.19981 16.2001 9.19981H1.8001C1.48184 9.19981 1.17661 9.07338 0.951569 8.84833C0.726526 8.62329 0.600098 8.31806 0.600098 7.9998ZM0.600098 13.9998C0.600098 13.6815 0.726526 13.3763 0.951569 13.1513C1.17661 12.9262 1.48184 12.7998 1.8001 12.7998H16.2001C16.5184 12.7998 16.8236 12.9262 17.0486 13.1513C17.2737 13.3763 17.4001 13.6815 17.4001 13.9998C17.4001 14.3181 17.2737 14.6233 17.0486 14.8483C16.8236 15.0734 16.5184 15.1998 16.2001 15.1998H1.8001C1.48184 15.1998 1.17661 15.0734 0.951569 14.8483C0.726526 14.6233 0.600098 14.3181 0.600098 13.9998Z"
      />
    </svg>
  )
}

import axios from "axios"

import type { BattleResult } from "types/battle"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetBattleResultResponseDataType = BattleResult

export const getBattleResult = async (token: string, hash: string) => {
  const res = await axios.get<GetBattleResultResponseDataType>(`${getApiBaseUrl()}/battles/${hash}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import { trackLoginClick } from "services/analytics"

import { Links } from "config/links"

import { Discord } from "components/shared/icons/discord"
import { DiscordSingle } from "components/shared/icons/discord-single"
import { FacebookSingleIcon } from "components/shared/icons/facebook-single"
import { InstagramSingleIcon } from "components/shared/icons/instagram-single"
import { LinkedinSingleIcon } from "components/shared/icons/linkedin-single"
import { MediumSingleIcon } from "components/shared/icons/medium-single"
import { Telegram } from "components/shared/icons/telegram"
import { TelegramSingleIcon } from "components/shared/icons/telegram-single"
import { Twitter } from "components/shared/icons/twitter"
import { TwitterSingle } from "components/shared/icons/twitter-single"

import type { MenuLink, SocialBtns, SubMenu } from "./domain"

const gameSubMenuColumns = [
  [
    // { label: "Pre-Registration", url: Links.PREREGISTRATION, target: "_self" },
    { label: "Game", url: Links.GAME, target: "_self", onClick: () => trackLoginClick("menu") },
    { label: "Cryptobots Gitbook", url: Links.WHITEPAPPER, target: "_self" },
  ],
]

const nftAndTokensSubMenuColumns = [
  [
    { label: "NFT Collections" },
    { label: "Cryptobots", url: Links.BOTS, target: "_self" },
    { label: "Genesis Staking", url: Links.STAKING, target: "_blank" },
    { label: "Classic Collection", url: Links.CLASSIC, target: "_self" },
    { label: "Wrap Classic", url: Links.WRAP, target: "_self" },
    { label: "🔥 Polygon Bots Bridge", url: Links.BRIDGE, target: "_self" },
    { label: "3D Bots", url: Links.BOTS_3D, target: "_self" },
  ],
  [
    { label: "" },
    { label: "Companions", url: Links.COMPANION, target: "_self" },
    { label: "Lands", url: Links.GAME, target: "_self", comingSoon: true },
  ],
]

const communitySubMenuColumns = [
  [
    { label: "Discord", url: Links.DISCORD, target: "_blank", icon: DiscordSingle },
    { label: "Twitter", url: Links.TWITTER, target: "_blank", icon: TwitterSingle },
    { label: "Telegram", url: Links.TELEGRAM, target: "_blank", icon: TelegramSingleIcon },
    { label: "Instagram", url: Links.INSTAGRAM, target: "_blank", icon: InstagramSingleIcon },
  ],
  [
    { label: "Facebook", url: Links.FB, target: "_blank", icon: FacebookSingleIcon },
    { label: "LinkedIn", url: Links.LINKEDIN, target: "_blank", icon: LinkedinSingleIcon },
    { label: "Medium", url: Links.MEDIUM, target: "_blank", icon: MediumSingleIcon },
  ],
]

export const menuItems: (MenuLink | SubMenu)[] = [
  { label: "Game", linkColumns: gameSubMenuColumns, bgClassName: "bg-[url(/images/pages/header/bg-game.png)]" },
  { label: "🔥 Rewards", url: Links.STAKE, target: "_self", eventName: "staking" },
  {
    label: "NFT",
    linkColumns: nftAndTokensSubMenuColumns,
    bgClassName: "bg-[url(/images/pages/header/bg-nft.png)]",
  },
  { label: "FAQ", url: Links.FAQ, target: "_self", eventName: "faq" },
  {
    label: "Community",
    linkColumns: communitySubMenuColumns,
    bgClassName: "bg-[url(/images/pages/header/bg-community.png)]",
  },
  { label: "news", url: Links.TWITTER, target: "_blank", eventName: "news" },
  { label: "team", url: Links.TEAM, target: "_self", eventName: "team" },
]

export const socialBtns: SocialBtns[] = [
  { content: <Discord />, href: Links.DISCORD, square: true, target: "_blank", eventName: "discord" },
  { content: <Twitter />, href: Links.TWITTER, square: true, target: "_blank", eventName: "twitter" },
  { content: <Telegram />, href: Links.TELEGRAM, square: true, target: "_blank", eventName: "telegram" },
]

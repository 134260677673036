import { claimRewards } from "./claim-rewards"
import { getFrozenRewards } from "./get-frozen-rewards"
import { getRewards } from "./get-rewards"
import spends from "./spends"

const rewards = {
  getRewards,
  getFrozenRewards,
  claimRewards,
  spends,
}

export default rewards

import axios from "axios"

import type { MetricsData } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = MetricsData & {
  credential: string
}

type ResponseData = {
  token: string
}

export const authWithGoogle = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/auth/google`, regBody)
  return returnIf200(res)
}

import type { Chain } from "@usedapp/core"
import { useCallback, useEffect, useState } from "react"

import { useMounted } from "components/hooks/use-mounted"
import { useWeb3Simple } from "components/hooks/web3/use-base"
import useSwitchNetwork from "components/hooks/web3/use-switch-networks"

import { Box } from "components/shared/box"
import { GradientButton } from "components/shared/gradient-button/gradient-button"
import { MetaMask } from "components/shared/icons/metamask"
import { Button } from "components/shared/landing/button"
import { Text } from "components/shared/text"
import { TextPrimary } from "components/shared/text-primary"

import { cutAddress } from "utils/web3/cut-address"

export function WrongNetworkPopupSpawn({ chain, showDisconnect = false }: { chain: Chain; showDisconnect: boolean }) {
  const mounted = useMounted()
  const [loading, setLoading] = useState(false)
  const [selectedChainId, setSelectedChainId] = useState<number>()
  const { switchNetwork } = useSwitchNetwork()
  const { account, chainId, disconnect } = useWeb3Simple()

  const switchToRequiredNetwork = useCallback(() => {
    setLoading(true)
    switchNetwork(chain.chainId).finally(() => {
      setLoading(false)
    })
  }, [chain, switchNetwork])

  useEffect(() => {
    if (chainId) {
      setSelectedChainId(chainId)
    }
  }, [chainId])

  if (!mounted || !account || selectedChainId === chain.chainId) {
    return null
  }

  return (
    <Box center className="fixed inset-0 bg-black/60 backdrop-blur-3xl z-60">
      <Box row className="left-0 bottom-0 w-full max-w-[90vw] md:max-w-[50vw] lg:max-w-[40vw] z-40 p-6 ">
        <Box
          full
          center
          className="gap-6 backdrop-blur-md bg-black/20 p-6 rounded-md border-[1px] border-gray-200/20 drop-shadow"
        >
          <MetaMask w={48} h={48} />
          <Text className="opacity-80 text-center">
            {cutAddress(account)}
            <Text as="span" className="text-[6px]">
              ({chainId})
            </Text>
          </Text>
          <Text className="text-h4 text-center">
            Please, switch the network to <TextPrimary>{chain.chainName}</TextPrimary>
          </Text>
          <Box className="w-full max-w-[16rem]">
            <GradientButton
              loading={loading}
              onClick={switchToRequiredNetwork}
              type="primary-purple"
              className="h-12 w-full rounded-[8px] p-1 mx-1"
            >
              {loading ? "Switching..." : `Switch to ${chain.chainName}`}
            </GradientButton>
          </Box>

          <Text size="sm" className="-mt-3 opacity-50 text-center">
            Or change the network manually on Metamask
          </Text>
          {showDisconnect && (
            <Button
              type="transparent"
              loading={loading}
              noOverlay
              noGradient
              noPadding
              noVolume
              onClick={disconnect}
              className="h-12 w-[12.5rem] rounded-[8px] p-1 mx-1 text-white border-none"
            >
              Disconnect
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

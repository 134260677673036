import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetMyAllianceSignResponseData = {
  message: {
    id: string
    owner: string
  }
  signature: string
}

export const getMyAllianceSign = async (token: string, allianceId: string) => {
  const res = await axios.get<GetMyAllianceSignResponseData>(
    `${getApiBaseUrl()}/admin/alliances/${allianceId}/show_signature`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type DeleteTeamResponseData = BotTeam

export const deleteTeam = async (token: string, teamId: string) => {
  const res = await axios.post<DeleteTeamResponseData>(
    `${getApiBaseUrl()}/rent_pools/team/bots/disband/${teamId}`,
    null,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

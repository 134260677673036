import account from "./account"
import alliance from "./alliance"
import battles from "./battles"
import botTeams from "./bot-teams"
import bots from "./bots"
import contracts from "./contracts"
import leaderboard from "./leaderboard"
import marketplace from "./marketplace"
import metabase from "./metabase"
import pool from "./pool"
import rewards from "./rewards"
import settings from "./settings"
import shipTeams from "./ship-teams"
import ships from "./ships"
import wallet from "./wallet"

export const api = {
  account,
  bots,
  botTeams,
  battles,
  marketplace,
  metabase,
  wallet,
  alliance,
  pool,
  contracts,
  rewards,
  leaderboard,
  ships,
  shipTeams,
  settings,
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export async function sendConfirmCodeOnEmail(token: string) {
  const res = await axios.post(`${getApiBaseUrl()}/account/repeat_verification`, null, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

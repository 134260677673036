import axios from "axios"
import { getApiBaseUrl, returnIf200 } from "../utils"
import type { Account } from "types/account"

type GetAccountResponseData = Account

export const getAccount = async (id: string): Promise<GetAccountResponseData | null> => {
  const res = await axios.get<GetAccountResponseData>(`${getApiBaseUrl()}/account/${id}`)
  return returnIf200(res)
}

import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type RentTeamResponseData = BotTeam

export const rentTeam = async (
  token: string,
  { ids, poolId, name }: { ids: string[]; poolId: string; name: string },
) => {
  const res = await axios.post<RentTeamResponseData>(
    `${getApiBaseUrl()}/rent_pools/team/rent`,
    { unit_ids: ids, id: poolId, name },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type IsExistResponseData = {
  exists: boolean
}

export const isExist = async (email: string) => {
  const res = await axios.get<IsExistResponseData>(`${getApiBaseUrl()}/email/${email}/exists`)
  return returnIf200(res)
}

import applications from "./applications"
import { createPool } from "./create-pool"
import { demoRepairBot } from "./demo-repair-bot"
import { getMyPool } from "./get-my-pool"
import { getMyRentItem } from "./get-my-rent-item"
import { getMyRentList } from "./get-my-rent-list"
import { getPool } from "./get-pool"
import { getPools } from "./get-pools"
import teams from "./teams"

const pool = {
  createPool,
  getMyPool,
  getPools,
  getPool,
  getMyRentItem,
  getMyRentList,
  demoRepairBot,

  applications,
  teams,
}

export default pool

import { useEffect, useMemo, useState } from "react"

export function useNoScroll() {
  const [noScroll, setNoScroll] = useState(false)
  useEffect(() => {
    if (noScroll) {
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      document.documentElement.style.setProperty("--screen-height", `${screenHeight}px`)
      document.documentElement.style.setProperty("--screen-width", `${screenWidth}px`)
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }, [noScroll])
  return useMemo(() => ({ noScroll, setNoScroll }), [noScroll])
}

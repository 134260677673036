import axios from "axios"

import type { Alliance } from "types/alliance"
import type { PageableDataType } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetAlliancesResponseData = {
  alliances: Alliance[]
} & PageableDataType

export const getAlliances = async ({
  currentPage,
  tag,
}: {
  currentPage?: number
  tag?: string
  filters?: { min: string; max: string }
}) => {
  const queryParams = []
  if (currentPage) {
    queryParams.push(`page=${currentPage}`)
  }
  if (tag) {
    queryParams.push(`filter[tag]=${tag}`)
  }
  const queryParamsString = queryParams ? `?${queryParams.join("&")}` : ""
  const res = await axios.get<GetAlliancesResponseData>(`${getApiBaseUrl()}/alliances${queryParamsString}`)
  return returnIf200(res)
}

import axios from "axios"

import type { AllianceApplication } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type declineApplicationResponseData = AllianceApplication

export const declineApplication = async (token: string, accountId: string) => {
  const res = await axios.delete<declineApplicationResponseData>(
    `${getApiBaseUrl()}/admin/alliances/application/${accountId}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

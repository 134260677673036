import axios from "axios"

import type { PageableBots } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetMyBotsResponseData = PageableBots

export const getMyBots = async (token: string, queryParams = "?page_size=1000&order_by=power") => {
  const res = await axios.get<GetMyBotsResponseData>(`${getApiBaseUrl()}/bots${queryParams}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

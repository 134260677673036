import React from "react"

export function Telegram({ fill = "white", h = 28, w = 28 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 30 30">
      <g>
        <path
          fill={fill}
          d="M1 14C1 21.732 7.26801 28 15 28C22.732 28 29 21.732 29 14C29 6.26801 22.732 0 15 0C7.26801 0 1 6.26801 1 14ZM12.4333 20.4167L12.6715 16.848L12.6713 16.8478L19.1632 10.9894C19.4481 10.7366 19.101 10.6132 18.7228 10.8426L10.7109 15.8972L7.25023 14.8171C6.50288 14.5882 6.49751 14.0747 7.41802 13.7055L20.9035 8.5055C21.5194 8.22588 22.1139 8.65344 21.8788 9.59613L19.5822 20.4185C19.4218 21.1875 18.9572 21.3715 18.3133 21.0162L14.8149 18.4315L13.1333 20.0667C13.128 20.0718 13.1228 20.077 13.1175 20.0821C12.9294 20.2652 12.7738 20.4167 12.4333 20.4167Z"
        />
      </g>
    </svg>
  )
}

import { useEthers } from "@usedapp/core"
import { Wallet } from "ethers"
import { useCallback, useEffect, useState } from "react"

const WALLET_ADDRESS_KEY = "WALLET_ADDRESS"
const WALLET_DATA_KEY = "WALLET_DATA"

let cashedSigner: Wallet | undefined = undefined

export function useNativeWalletProvider() {
  const { library } = useEthers()
  const [walletAddress, setWalletAddress] = useState<string>()

  const checkPrivateKey = useCallback((address: string) => {
    return address.toLowerCase() === cashedSigner?.address.toLowerCase()
  }, [])

  const saveWallet = useCallback((address: string, privateData?: string) => {
    if (localStorage) {
      localStorage.setItem(WALLET_ADDRESS_KEY, address)

      if (privateData) {
        localStorage.setItem(WALLET_DATA_KEY, privateData)
      }
    } else {
      console.error("localStorage not found")
    }
  }, [])

  const createWallet = useCallback(() => {
    const wallet = Wallet.createRandom()

    saveWallet(wallet.address, wallet.mnemonic.phrase)

    if (library) {
      cashedSigner = wallet.connect(library)
    }

    return wallet
  }, [library, saveWallet])

  const addWallet = useCallback(
    async (address: string) => {
      setWalletAddress(address)
      saveWallet(address)
    },
    [saveWallet],
  )

  const addWalletWithPrivateKey = useCallback(
    (privateKey: string) => {
      let wallet: Wallet | null = null

      try {
        wallet = new Wallet(privateKey)
      } catch (err) {
        console.error(err)
      }

      if (!wallet || !wallet.privateKey) {
        return false
      }

      setWalletAddress(wallet.address)
      saveWallet(wallet.address, wallet.privateKey)

      if (library) {
        cashedSigner = wallet.connect(library)
      }

      return true
    },
    [library, saveWallet],
  )

  const addWalletWithMnemonic = useCallback(
    (mnemonicString: string) => {
      const wallet = Wallet.fromMnemonic(mnemonicString)

      if (!wallet.mnemonic) {
        return false
      }

      setWalletAddress(wallet.address)
      saveWallet(wallet.address, wallet.mnemonic.phrase)

      if (library) {
        cashedSigner = wallet.connect(library)
      }

      return true
    },
    [library, saveWallet],
  )

  const cleanWalletData = useCallback(() => {
    if (localStorage) {
      localStorage.removeItem(WALLET_ADDRESS_KEY)
      localStorage.removeItem(WALLET_DATA_KEY)
    }

    cashedSigner = undefined
  }, [])

  useEffect(() => {
    // localStorage.setItem(
    //   "0xA1c4C7ee6f7a2Dc61a073e0D5B5148506e1eB632",
    //   "c2691d287842a57b4b70e736f8f9e3fcd1e6c205c28cf38e79463e91e94b48c9",
    // )

    const address = localStorage.getItem(WALLET_ADDRESS_KEY)

    if (address) {
      setWalletAddress(address)
    }

    const data = localStorage.getItem(WALLET_DATA_KEY)

    if (data && library && !cashedSigner) {
      let wallet: Wallet | null = null

      try {
        if (data.includes(" ")) {
          wallet = Wallet.fromMnemonic(data)
        } else {
          wallet = new Wallet(data)
        }
      } catch (err) {
        console.error(err)
      }

      if (!wallet) {
        return
      }

      if (address?.toLowerCase() === wallet.address.toLowerCase()) {
        cashedSigner = wallet.connect(library)
      }
    }
  }, [library])

  return {
    signer: cashedSigner,
    account: walletAddress?.toLowerCase(),
    createWallet,
    addWallet,
    addWalletWithPrivateKey,
    addWalletWithMnemonic,
    cleanWalletData,
    checkPrivateKey,
  }
}

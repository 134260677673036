import axios from "axios"

import type { MetricsData } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = MetricsData & {
  email: string
  password: string
  password_confirmation: string
}

type ResponseData = {
  bio: string
  email: string
  external_links: []
  id: string
  nickname: string
  profile_picture: string
  social_networks: string
  wallet: string
}

export const registerWithEmail = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/register_free`, regBody)
  return returnIf200(res)
}

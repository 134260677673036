import { Box } from "../box"
import { LogoDark } from "../icons/logo-dark_obsolete"
import { Text } from "../text"

export type LogoTextProps = {
  showLogoName?: boolean
}

export function LogoText({ showLogoName = true }: LogoTextProps) {
  return (
    <Box row center>
      <LogoDark />
      {showLogoName && <Text className="ml-2 font-bold uppercase">Cryptobots</Text>}
    </Box>
  )
}

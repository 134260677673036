import axios from "axios"
import { getApiBaseUrl, returnIf200 } from "../utils"
import type { Account, AccountOptions } from "types/account"

type UpdateAccountResponseData = Account

export const updateAccount = async (
  token: string,
  reqBody: {
    bio?: string
    nickname?: string
    profile_picture?: string
    options?: AccountOptions
  },
) => {
  const res = await axios.post<UpdateAccountResponseData>(`${getApiBaseUrl()}/account/update`, reqBody, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

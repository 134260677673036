import axios from "axios"

import type { AllianceApplication } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type CreateApplicationResponseData = AllianceApplication
type CreateApplicationRequestData = {
  bio: string
  is_scholar: boolean
}

export const createApplication = async (token: string, allianceId: string, data: CreateApplicationRequestData) => {
  const res = await axios.post<CreateApplicationResponseData>(
    `${getApiBaseUrl()}/alliances/${allianceId}/apply`,
    data,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import axios from "axios"

import type { Ship } from "types/ship"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetShipResponseData = { ship: Ship }

export const getShip = async (id: number) => {
  const res = await axios.get<GetShipResponseData>(`${getApiBaseUrl()}/ship/${id}`)
  return returnIf200(res)
}

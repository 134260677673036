import { Box } from "components/shared/box"

type ActiveUnderlineProps = {
  className: string
}

export function ActiveUnderline({ className }: ActiveUnderlineProps) {
  return (
    <Box className={`w-full h-[4px] bg-gradient-to-r from-[#8c37f100] via-[#B069FF80] to-[#a447f900] ${className}`} />
  )
}

import { authWithGoogle } from "./auth-with-google"
import { confirmEmailWithCode } from "./confirm-email-with-code"
import { confirmEmailWithHash } from "./confirm-email-with-hash"
import { getAccount } from "./get-account"
import { getMyAccount } from "./get-my-account"
import { isExist } from "./is-exist"
import { loginWithEmail } from "./login-with-email"
import { loginWithWallet } from "./login-with-wallet"
import { registerWithEmail } from "./register-with-email"
import { registerWithWallet } from "./register-with-wallet"
import { requestResetPassword } from "./request-reset-password"
import { resetPassword } from "./reset-password"
import { resetPasswordTokenVerify } from "./reset-password-token-verify"
import { sendConfirmCodeOnEmail } from "./send-confirm-code-on-email"
import { updateAccount } from "./update-account"

const account = {
  sendConfirmCodeOnEmail,
  registerWithEmail,
  registerWithWallet,
  authWithGoogle,
  loginWithEmail,
  loginWithWallet,
  getAccount,
  getMyAccount,
  updateAccount,
  confirmEmailWithCode,
  confirmEmailWithHash,
  isExist,
  requestResetPassword,
  resetPasswordTokenVerify,
  resetPassword,
}

export default account

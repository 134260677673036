import axios from "axios"

import type { Pool } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetPoolsResponseData = Array<Pool>

export const getPools = async (token: string) => {
  const res = await axios.get<GetPoolsResponseData>(`${getApiBaseUrl()}/rent_pools`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })

  return returnIf200(res)
}

import Image from "next/image"
import React, { useCallback } from "react"
import type { MouseEvent } from "react"

import { Box } from "components/shared/box"
import { Rotating } from "components/shared/icons/rotating"

import styles from "./index.module.scss"

type ButtonType = "primary" | "secondary" | "icon" | "transparent" | "action"
type ButtonSize = "sm" | "md" | "lg"

type ButtonProps = {
  onClick?: (e?: MouseEvent) => void
  children: React.ReactNode | string | null
  type?: ButtonType
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  noPadding?: boolean
  noOverlay?: boolean
  noGradient?: boolean
  noVolume?: boolean
  className?: string
  standardFontSize?: boolean
  rounded?: boolean
  as?: "button" | "div"
  showBot?: boolean
  noTextShadow?: boolean
}

export function Button({
  children,
  onClick,
  loading,
  disabled = false,
  noPadding = false,
  noOverlay = false,
  noGradient = false,
  noVolume = false,
  size = "md",
  type = "primary",
  className = "",
  rounded = true,
  as = "button",
  showBot = false,
  noTextShadow = false,
}: ButtonProps) {
  const classNames = [styles.button, styles[type]]
  if (!noGradient) {
    classNames.push(styles[`${type}__gradient`])
  }
  if (!noPadding) {
    classNames.push(styles[size])
  }
  if (noTextShadow) {
    classNames.push(styles["button_no-text-shadow"])
  }
  if (noOverlay) {
    classNames.push(styles.no_overlay)
  }
  classNames.push(className)
  if (type === "action") {
    classNames.push(styles.action)
  }
  if (rounded) {
    classNames.push("rounded")
  }

  const Tag = as

  const handleClick = useCallback(
    (e: { stopPropagation: () => void; nativeEvent: { stopImmediatePropagation: () => void } }) => {
      if (onClick) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        onClick()
      }
    },
    [onClick],
  )
  return (
    <Tag className={classNames.join(" ")} onClick={handleClick} disabled={disabled || loading}>
      {!noVolume && <div className={styles.volume} />}
      {!noOverlay && <div className={styles.overlay} />}
      {/* TODO doesn't work */}
      <Box full center row className={`z-10 ${type === "action" ? "action-box p-2 relative" : ""}`}>
        {showBot && (
          <Box className="h-full w-[3rem] absolute right-0 -z-1 pointer-events-none">
            <Image src="/images/pages/bot-3d/top-section/button-bot.png" alt="button-bot" layout="fill" />
          </Box>
        )}
        {loading && <Rotating className="animate-spin mr-4 w-[1rem]" />}
        {children}
      </Box>
    </Tag>
  )
}

import axios from "axios"
import { amplitudeMetabaseService } from "services/amplitude-metabase"

import type { Account } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

type GetMyAccountResponseData = Account

export const getMyAccount = async (token: string) => {
  const res = await axios.get<GetMyAccountResponseData>(`${getApiBaseUrl()}/account`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })

  const account = returnIf200(res)

  if (account) {
    amplitudeMetabaseService.updateUserId(account.id)
  }

  return account
}

import React from "react"

export function LinkedinSingleIcon({ fill = "white", h = 16, w = 16 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 3H3.74766C3.33516 3 3 3.33984 3 3.75703V12.743C3 13.1602 3.33516 13.5 3.74766 13.5H12.75C13.1625 13.5 13.5 13.1602 13.5 12.743V3.75703C13.5 3.33984 13.1625 3 12.75 3ZM6.17344 12H4.61719V6.98906H6.17578V12H6.17344ZM5.39531 6.30469C4.89609 6.30469 4.49297 5.89922 4.49297 5.40234C4.49297 4.90547 4.89609 4.5 5.39531 4.5C5.89219 4.5 6.29766 4.90547 6.29766 5.40234C6.29766 5.90156 5.89453 6.30469 5.39531 6.30469ZM12.007 12H10.4508V9.5625C10.4508 8.98125 10.4391 8.23359 9.64219 8.23359C8.83125 8.23359 8.70703 8.86641 8.70703 9.52031V12H7.15078V6.98906H8.64375V7.67344H8.66484C8.87344 7.27969 9.38203 6.86484 10.1391 6.86484C11.7141 6.86484 12.007 7.90312 12.007 9.25312V12Z"
        fill={fill}
      />
    </svg>
  )
}

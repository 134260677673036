import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type LeaderboardPlayer = {
  alliance: string | null
  alliance_icon: string | null
  alliance_name: string | null
  nickname: string
  icon: string
  id: string
  points: number
}

export type GetTopPlayersData = {
  data: LeaderboardPlayer[]
}

export const getTopPlayers = async () => {
  const res = await axios.get<GetTopPlayersData>(`${getApiBaseUrl()}/leaderboard/testnet/players`)
  return returnIf200(res)
}

import axios from "axios"

import type { PoolApplication } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type GetMyApplicationsResponseData = Array<PoolApplication>

export const getMyApplications = async (token: string) => {
  const res = await axios.get<GetMyApplicationsResponseData>(`${getApiBaseUrl()}/rent_pools/applications/my`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import { buyTeam } from "./buy-team"
import { deleteTeam } from "./delete-team"
import { rentDemoTeam } from "./rent-demo-team"
import { rentTeam } from "./rent-team"

const teams = {
  rentTeam,
  rentDemoTeam,
  deleteTeam,
  buyTeam,
}

export default teams

import React from "react"
import { getIconSize } from "utils/get-icon-size"

export function LeftArrow({
  fill = "white",
  h = 16,
  w = 10,
  className = "",
  pale,
}: {
  fill?: string
  h?: string | number
  w?: string | number
  className?: string
  pale?: boolean
}) {
  return (
    <svg
      width={w}
      height={h}
      className={`w-[${getIconSize(w)}] h-[${getIconSize(h)}] ${pale ? "opacity-40" : ""} ${className}`}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.439608 7.99996C0.439608 7.7132 0.549096 7.42649 0.767613 7.20786L7.64732 0.328226C8.08496 -0.109409 8.79451 -0.109409 9.23197 0.328226C9.66942 0.765684 9.66942 1.47509 9.23197 1.91276L3.14442 7.99996L9.23175 14.0872C9.66921 14.5248 9.66921 15.2342 9.23175 15.6716C8.79429 16.1094 8.08475 16.1094 7.64711 15.6716L0.7674 8.79205C0.548847 8.57332 0.439608 8.2866 0.439608 7.99996Z"
        fill={fill}
      />
    </svg>
  )
}

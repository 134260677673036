import { Box } from "components/shared/box"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

import type { SubMenu } from "./domain"
import styles from "./header.module.scss"

type DesktopSubmenuProps = {
  currentDesktopSubMenu: SubMenu
}

export function DesktopSubmenu({ currentDesktopSubMenu }: DesktopSubmenuProps) {
  return (
    <Box
      className={`w-full absolute top-[calc(60px+1.5rem)] left-0 right-0 ${styles["header_submenu"]} bg-[#4a09ac] ${currentDesktopSubMenu.bgClassName} bg-cover`}
    >
      <Box row center className={`w-full max-w-[1200px] text-white justify-between px-4 md:px-12 lg:px-6 self-center`}>
        <Box row className="py-[64px] w-full">
          <Box className={`flex-1 ${styles["header_submenu-title"]}`}>
            <Text className="text-[40px] leading-[40px]">{currentDesktopSubMenu.label}</Text>
          </Box>
          <Box row className="flex-1 gap-2">
            {currentDesktopSubMenu.linkColumns.map((col, idx) => (
              <Box className="flex-1 gap-2 capitalize" key={idx}>
                {col.map((textOrLink, colIdx) =>
                  "url" in textOrLink ? (
                    <Box row className="items-center gap-2" key={`${textOrLink.label}-${colIdx}`}>
                      <Link
                        className="font-semibold text-light flex items-center gap-2"
                        disabled={textOrLink.comingSoon}
                        href={textOrLink.url}
                        target={textOrLink.target}
                      >
                        {textOrLink.icon && <textOrLink.icon fill="currentColor" h={16} w={16} />}
                        {textOrLink.label}
                      </Link>
                      {textOrLink.comingSoon && (
                        <div className="bg-white/25 px-2 py-[2px] rounded-[14px]">
                          <Text className="whitespace-nowrap text-[8px] leading-[10px] uppercase" weight="bold">
                            Coming soon
                          </Text>
                        </div>
                      )}
                    </Box>
                  ) : (
                    <Text
                      className="text-[12px] leading-[12px] h-[12px] opacity-60"
                      key={`${textOrLink.label}-${colIdx}`}
                    >
                      {textOrLink.label}
                    </Text>
                  ),
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

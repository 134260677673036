import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type DisbandTeamResponseData = {
  message: {
    botOrShipDamages: [string, string, string]
    deadline: string
    teamId: string
  }
  signature: string
}

export const disbandTeam = async (token: string, id: string) => {
  const res = await axios.get<DisbandTeamResponseData>(`${getApiBaseUrl()}/bot_teams/${id}/disband`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

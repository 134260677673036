import { connectWallet } from "./connect-wallet"
import { exists } from "./exists"
import { getNonce } from "./get-nonce"

const wallet = {
  getNonce,
  connectWallet,
  exists,
}

export default wallet

import axios from "axios"

import type { ShipTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetShipTeamsResponseData = Array<ShipTeam>

export const myShipTeams = async (token: string) => {
  const res = await axios.get<GetShipTeamsResponseData>(`${getApiBaseUrl()}/ship_teams`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

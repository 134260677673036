import axios from "axios"

import type { Spend } from "types/reward"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type DeleteSpendsResponseData = Spend

export const deleteSpend = async (token: string, id: string) => {
  const res = await axios.delete<DeleteSpendsResponseData>(`${getApiBaseUrl()}/rewards/early/${id}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

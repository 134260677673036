import { Fragment } from "react"
import { trackHeaderLinkClick } from "services/analytics"

import { Links } from "config/links"

import { Box } from "components/shared/box"
import { GradientButton } from "components/shared/gradient-button/gradient-button"
import { ArrowDown } from "components/shared/icons/arrow-down"
import { LeftArrow } from "components/shared/icons/left-arrow"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

import styles from "./header.module.scss"
import { menuItems } from "./utils"

export function MobileMenu({
  openedSubMenuLabels,
  toggleSubMenu,
  toggleMobileMenu,
}: {
  openedSubMenuLabels: string[]
  toggleSubMenu: (label: string) => void
  toggleMobileMenu: () => void
}) {
  return (
    <div
      className={`fixed left-0 top-[calc(60px+1.5rem)] w-[100%] bg-[#4A09AC] max-h-[calc(100vh-60px-1.5rem)] z-50 lg:hidden overflow-hidden overflow-y-auto ${styles["header_mobile-menu"]}`}
    >
      <Box
        full
        className="px-4 md:px-12 pt-6 pb-20 bg-[url(/images/pages/header/bg-mobile.png)] bg-no-repeat flex flex-col"
      >
        <Box full center className="gap-y-6 text-white">
          <Link href={Links.GAME}>
            <GradientButton type="primary-purple" onClick={toggleMobileMenu} className="sm:hidden w-[160px] py-[6px]">
              Play Now
            </GradientButton>
          </Link>
          {menuItems
            .filter(item => item.label !== "Staking")
            .map((menuItem, idx) => {
              const isLink = "url" in menuItem
              const isOpened = openedSubMenuLabels.includes(menuItem.label)

              return isLink ? (
                <Link
                  key={`${menuItem.label}-${idx}`}
                  href={menuItem.url}
                  target={menuItem.target}
                  className="px-2 h-full uppercase text-sm items-center font-bold relative"
                  withRedirect
                  onClick={() => {
                    trackHeaderLinkClick(menuItem.eventName)
                  }}
                >
                  {menuItem.label}
                </Link>
              ) : (
                <Fragment key={`${menuItem.label}-${idx}`}>
                  <Link
                    onClick={() => toggleSubMenu(menuItem.label)}
                    className="md:flex h-full"
                    highlightOnHover={false}
                  >
                    <Box row className={`px-2 h-full uppercase text-sm font-bold items-center gap-2 relative`}>
                      {menuItem.label}
                      <Box className="absolute top-0 bottom-0 -right-2 justify-center">
                        <ArrowDown w={12} h={12} className={isOpened ? "" : "hidden"} />
                        <Box center className={`w-[12px] ${isOpened ? "hidden" : ""}`}>
                          <LeftArrow h={8} w={8} className="-rotate-90" />
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                  {isOpened &&
                    menuItem.linkColumns
                      .flat()
                      .filter(el => el.label)
                      .map((textOrLink, sumMenusIdx) =>
                        "url" in textOrLink ? (
                          <Box className="items-center gap-1" key={textOrLink.label}>
                            <Link
                              className="font-semibold text-light flex items-center gap-2"
                              disabled={textOrLink.comingSoon}
                              href={textOrLink.url}
                              target={textOrLink.target}
                            >
                              {textOrLink.icon && <textOrLink.icon fill="currentColor" h={16} w={16} />}
                              {textOrLink.label}
                            </Link>
                            {textOrLink.comingSoon && (
                              <div
                                className="bg-white/25 px-2 py-[2px] rounded-[14px]"
                                key={`${textOrLink.label}-${sumMenusIdx}`}
                              >
                                <Text className="whitespace-nowrap text-[8px] leading-[10px] uppercase" weight="bold">
                                  Coming soon
                                </Text>
                              </div>
                            )}
                          </Box>
                        ) : (
                          <Text className="text-[12px] leading-[12px] h-[12px] opacity-60" key={textOrLink.label}>
                            {textOrLink.label}
                          </Text>
                        ),
                      )}
                </Fragment>
              )
            })}
        </Box>
      </Box>
    </div>
  )
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  email: string
}

export async function requestResetPassword(regBody: RequestData) {
  const res = await axios.post(`${getApiBaseUrl()}/reset_password`, regBody)
  return returnIf200(res)
}

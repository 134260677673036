import React from "react"

export function TelegramSingleIcon({ fill = "white", h = 16, w = 16 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8125 2C4.60234 2 2 4.60234 2 7.8125C2 11.0227 4.60234 13.625 7.8125 13.625C11.0227 13.625 13.625 11.0227 13.625 7.8125C13.625 4.60234 11.0227 2 7.8125 2ZM10.5067 5.95297C10.4192 6.87207 10.0407 9.10245 9.84809 10.1319C9.76662 10.5674 9.60617 10.7135 9.45087 10.7278C9.11337 10.7589 8.85702 10.5047 8.53009 10.2904C8.01852 9.95506 7.72951 9.7463 7.23291 9.41907C6.65905 9.04091 7.03107 8.83313 7.35812 8.49329C7.44371 8.40439 8.93094 7.05165 8.95972 6.92893C8.9633 6.91358 8.96675 6.85627 8.93267 6.82618C8.89859 6.79609 8.84853 6.80628 8.81232 6.81446C8.76102 6.82612 7.94377 7.36629 6.36057 8.43498C6.12862 8.59426 5.91851 8.67187 5.73024 8.6678C5.5227 8.66333 5.12349 8.55048 4.8267 8.45398C4.4627 8.33567 4.17338 8.27312 4.19858 8.07216C4.2117 7.96748 4.35584 7.86045 4.631 7.75107C6.32547 7.01282 7.45538 6.5261 8.02072 6.29091C9.63491 5.6195 9.97032 5.50287 10.1889 5.49898C10.237 5.49819 10.3445 5.51009 10.4142 5.5666C10.4605 5.60686 10.49 5.66303 10.4969 5.72401C10.5087 5.79972 10.512 5.87652 10.5067 5.95297Z"
        fill={fill}
      />
    </svg>
  )
}

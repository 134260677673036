import axios from "axios"

import type { Account } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type RankingStatsData = {
  mmr: number
  points: number
  winrate: number
}

export type BucketLeague = "silver" | "gold"

export type RankingBucketData = {
  damage_settings: {
    bot_base_damage: number
    damage_balance_enabled: boolean
    damage_percent: number
    ship_base_damage: number
    win_damage_enabled: boolean
    win_damage_percent: number
  }
  from: number
  lose: number
  multiplier: number
  name: BucketLeague
  to: number
  win: number
}

type AccountRankingData = Account & RankingStatsData

type MyLeaderboard = {
  account: AccountRankingData
  ranking: number
  buckets: Array<RankingBucketData>
}

export const getMy = async (token: string) => {
  const res = await axios.get<MyLeaderboard>(`${getApiBaseUrl()}/my/leaderboard`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

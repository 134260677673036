import React from "react"

export function Instagram({ fill = "white", h = 28, w = 28 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 28 28">
      <g>
        <path
          d="M14.5 0C7.04416 0 1 6.04416 1 13.5C1 20.9558 7.04416 27 14.5 27C21.9558 27 28 20.9558 28 13.5C28 6.04416 21.9558 0 14.5 0ZM11.5318 6.3435C12.2999 6.30855 12.5453 6.3 14.5007 6.3H14.4984C16.4544 6.3 16.6989 6.30855 17.467 6.3435C18.2335 6.3786 18.757 6.49995 19.216 6.678C19.69 6.86176 20.0905 7.10776 20.491 7.50826C20.8915 7.90847 21.1375 8.31017 21.322 8.78373C21.499 9.24153 21.6205 9.76474 21.6565 10.5312C21.691 11.2993 21.7 11.5447 21.7 13.5001C21.7 15.4555 21.691 15.7003 21.6565 16.4683C21.6205 17.2345 21.499 17.7579 21.322 18.2158C21.1375 18.6892 20.8915 19.0909 20.491 19.4911C20.0909 19.8916 19.6898 20.1382 19.2164 20.3221C18.7583 20.5002 18.2345 20.6215 17.468 20.6566C16.7 20.6916 16.4553 20.7001 14.4998 20.7001C12.5445 20.7001 12.2993 20.6916 11.5312 20.6566C10.7649 20.6215 10.2415 20.5002 9.78343 20.3221C9.31017 20.1382 8.90847 19.8916 8.50841 19.4911C8.10806 19.0909 7.86206 18.6892 7.678 18.2157C7.5001 17.7579 7.37875 17.2347 7.3435 16.4682C7.3087 15.7001 7.3 15.4555 7.3 13.5001C7.3 11.5447 7.309 11.2991 7.34335 10.5311C7.37785 9.76489 7.49935 9.24153 7.67785 8.78358C7.86236 8.31017 8.10836 7.90847 8.50886 7.50826C8.90907 7.10791 9.31077 6.86191 9.78433 6.678C10.2421 6.49995 10.7653 6.3786 11.5318 6.3435Z"
          fill={fill}
        />
        <path
        fillRule="evenodd"
        clipRule="evenodd"
          d="M13.8548 7.5975C13.9802 7.59731 14.1151 7.59737 14.2607 7.59743L14.5007 7.5975C16.4231 7.5975 16.651 7.6044 17.4101 7.6389C18.1121 7.671 18.4931 7.78831 18.7469 7.88686C19.083 8.01736 19.3225 8.17336 19.5744 8.42536C19.8264 8.67736 19.9824 8.91737 20.1132 9.25337C20.2117 9.50687 20.3292 9.88788 20.3611 10.5899C20.3956 11.3489 20.4031 11.5769 20.4031 13.4984C20.4031 15.4199 20.3956 15.6479 20.3611 16.4069C20.329 17.109 20.2117 17.49 20.1132 17.7435C19.9827 18.0795 19.8264 18.3187 19.5744 18.5706C19.3224 18.8226 19.0831 18.9786 18.7469 19.1091C18.4934 19.2081 18.1121 19.3251 17.4101 19.3572C16.6511 19.3917 16.4231 19.3992 14.5007 19.3992C12.5781 19.3992 12.3503 19.3917 11.5913 19.3572C10.8893 19.3248 10.5083 19.2075 10.2543 19.1089C9.91831 18.9784 9.67831 18.8224 9.4263 18.5704C9.1743 18.3184 9.0183 18.079 8.8875 17.7429C8.78895 17.4894 8.6715 17.1084 8.63954 16.4063C8.60504 15.6473 8.59814 15.4193 8.59814 13.4966C8.59814 11.5739 8.60504 11.3471 8.63954 10.5881C8.67165 9.88608 8.78895 9.50507 8.8875 9.25127C9.018 8.91527 9.1743 8.67526 9.4263 8.42326C9.67831 8.17126 9.91831 8.01526 10.2543 7.88446C10.5081 7.78546 10.8893 7.66845 11.5913 7.6362C12.2555 7.6062 12.5129 7.5972 13.8548 7.5957V7.5975ZM18.344 8.79302C17.867 8.79302 17.48 9.17957 17.48 9.65672C17.48 10.1337 17.867 10.5207 18.344 10.5207C18.821 10.5207 19.2081 10.1337 19.2081 9.65672C19.2081 9.17972 18.821 8.79272 18.344 8.79272V8.79302ZM10.8032 13.5001C10.8032 11.4581 12.4587 9.80261 14.5006 9.80253C16.5425 9.80253 18.1976 11.4581 18.1976 13.5001C18.1976 15.542 16.5427 17.1969 14.5007 17.1969C12.4587 17.1969 10.8032 15.542 10.8032 13.5001Z"
          fill={fill}
        />
        <path
          d="M14.5007 11.1001C15.8261 11.1001 16.9008 12.1746 16.9008 13.5001C16.9008 14.8255 15.8261 15.9001 14.5007 15.9001C13.1752 15.9001 12.1007 14.8255 12.1007 13.5001C12.1007 12.1746 13.1752 11.1001 14.5007 11.1001Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

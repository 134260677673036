import axios from "axios"

import type { BattleCounters } from "types/battle"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetBattleCountersResponseDataType = BattleCounters

export const getBattleCounters = async (token: string, forShips?: boolean) => {
  const res = await axios.get<GetBattleCountersResponseDataType>(
    `${getApiBaseUrl()}/battles/counters${forShips ? "?type=ships" : ""}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

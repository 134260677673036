import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type BuyTeamResponseData = {
  message: {
    buyer: string
    deadline: string
    id: Array<string>
    nft: string
  }
  signature: string
}

export const buyTeam = async (token: string, teamId: string) => {
  const res = await axios.post<BuyTeamResponseData>(
    `${getApiBaseUrl()}/free_pools/buy_team?bot_team_id=${teamId}`,
    {},
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import Head from "next/head"
import React, { useEffect } from "react"

import { second } from "utils/time"

import { Tracking } from "./tracking"

let canReload = true
export function HtmlHead() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(
          registration => {
            console.log("ServiceWorker registration successful with scope: ", registration.scope)
            registration.addEventListener("updatefound", () => {
              if (canReload) {
                canReload = false
                // window.location.reload()
              }
            })
            // if (registration.waiting) {
            //   console.log("Inside waiting", registration.waiting.postMessage)
            //   navigator.serviceWorker.addEventListener("controllerchange", () => {
            //     alert("New version of app has been installed. Please restart the app")
            //   })
            //   registration.waiting.postMessage({ type: "SKIP_WAITING" })
            // }
            setInterval(() => {
              if (registration.update) {
                registration.update()
              }
            }, second * 10)
          },
          err => {
            console.log("ServiceWorker registration failed: ", err)
          },
        )
        .catch(err => {
          console.log(err)
        })
    } else {
      console.log("service worker is not supported")
    }
  }, [])

  return (
    <Head>
      <meta charSet="utf-8" key="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" key="httpEquiv" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        key="viewport"
      />
      <title>Cryptobots Game</title>
      <meta
        content="Engaging Play-to-Earn focused on a long-running stable economy"
        name="description"
        key="description"
      />
      <meta name="twitter:card" content="summary_large_image" key="twitterCard" />
      <meta name="twitter:site" content="@cryptobots_game" key="twitterSite" />
      <meta name="twitter:creator" content="" key="twitterCreator" />
      <meta name="twitter:title" content="Cryptobots Game" key="twitterTitle" />
      <meta
        name="twitter:description"
        content="Engaging Play-to-Earn focused on a long-running stable economy"
        key="twitterDescription"
      />
      <meta
        name="twitter:image"
        content="https://cryptobots.me/images/layout/head/meta-image-11221958.png"
        key="twitterImage"
      />
      <meta name="twitter:url" content="https://cryptobots.me/" key="twitterUrl" />
      <meta content="Cryptobots Game" property="og:title" key="ogTitle" />
      <meta
        content="Engaging Play-to-Earn focused on a long-running stable economy"
        property="og:description"
        key="ogDescription"
      />
      <meta
        content="https://cryptobots.me/images/layout/head/meta-image-11221958.png"
        property="og:image"
        key="ogImage"
      />
      <meta property="og:type" content="website" key="ogType" />
      <meta property="og:updated_time" content="2022-04-18T15:18:00-03:00" key="ogTime" />
      <meta
        name="ahrefs-site-verification"
        content="172a4e4a7ff81516244bf448e9b9d101864c6c729b5f54e3c7cc625fc83620a8"
        key="ogVerify"
      />
      <link rel="shortcut icon" type="image/x-icon" href="/icons/favicon.ico" key="shortcutIcon" />
      <link rel="icon" href="/icons/logo.svg" type="image/svg+xml" key="logoIcon" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" key="apple-touch-icon" />
      <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" key="icon32" />
      <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" key="icon16" />
      <link rel="manifest" href="site.webmanifest.json" key="webmainfest" />
      <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#ff46aa" key="mask-icon" />
      <meta name="apple-mobile-web-app-title" content="Cryptobots Game" key="apple-mobile-web-app-title" />
      <meta name="application-name" content="Cryptobots Game" key="application-name" />
      <meta name="msapplication-TileColor" content="#18124e" key="msapplication-TileColor" />
      <meta name="theme-color" content="#ffffff" key="theme-color" />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="splash_screens/iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/10.2__iPad_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/12.9__iPad_Pro_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/12.9__iPad_Pro_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        href="splash_screens/iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        href="splash_screens/iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/iPhone_11__iPhone_XR_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/10.5__iPad_Air_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        href="splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/10.5__iPad_Air_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/iPhone_11__iPhone_XR_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="splash_screens/iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        href="splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        href="splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/10.2__iPad_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        href="splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        href="splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png"
      />
      <Tracking />
    </Head>
  )
}

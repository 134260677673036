import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type ClientSettings = {
  apy_vl: number
  apy_best_player: number
  apy_lp_staking_bits: number
  apy_lp_staking_oil: number
  mp_show_fee: number
  polygon_blocks_per_second: number
  bits_usd_course: number
  oil_usd_course: number
  matic_usd_course: number
  total_claimed_bits: number
  total_claimed_matic: number
}

export const getSettings = async () => {
  const res = await axios.get<ClientSettings>(`${getApiBaseUrl()}/settings`)
  return returnIf200(res)
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  hash: string
}

export const confirmEmailWithHash = async (regBody: RequestData) => {
  const res = await axios.post(`${getApiBaseUrl()}/verify_account`, regBody)
  return returnIf200(res)
}

export type Platform = "ios" | "android" | "ipados" | "windows" | "macos" | "linux"

export function detectPlatform(): Platform | null {
  if (typeof window === "undefined") {
    return null
  }
  const { userAgent, platform } = window.navigator

  if (["Macintosh", "MacIntel", "MacPPC", "Mac68K"].indexOf(platform) !== -1) {
    return navigator.maxTouchPoints === 0 ? "macos" : "ipados"
  } else if (["iPhone", "iPad", "iPod"].indexOf(platform) !== -1) {
    return "ios"
  } else if (["Win32", "Win64", "Windows", "WinCE"].indexOf(platform) !== -1) {
    return "windows"
  } else if (/Android/.test(userAgent)) {
    return "android"
  } else if (/Linux/.test(platform)) {
    return "linux"
  }
  return null
}

export function detectWebview() {
  if (typeof window === "undefined") {
    return null
  }
  const { userAgent } = window.navigator

  return userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios)).*mobile(\/[a-z0-9]+)?$/)
}

export function detectMobile() {
  if (typeof window === "undefined") {
    return null
  }
  const { userAgent } = window.navigator
  return userAgent.toLowerCase().match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)
}

export function detectBrowser() {
  if (typeof window === "undefined") {
    return null
  }
  const { userAgent } = window.navigator

  const agent = userAgent.toLowerCase()

  if (agent.match(/chrome|chromium|crios/i)) {
    return "chrome"
  } else if (agent.match(/firefox|fxios/i)) {
    return "firefox"
  } else if (agent.match(/safari/i)) {
    return "safari"
  } else if (agent.match(/opr\//i)) {
    return "opera"
  } else if (agent.match(/edg/i)) {
    return "edge"
  }

  return null
}

export function detectInApp() {
  if (typeof window === "undefined") {
    return null
  }
  const { userAgent } = window.navigator

  const rules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari/)", "Android.*(wv)", "Slack/*"]
  const regex = new RegExp(`(${rules.join("|")})`, "ig")
  return Boolean(userAgent.match(regex))
}

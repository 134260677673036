import { api } from "services/api"

import { detectBrowser, detectPlatform } from "../../utils/browser/detect-platform"
import type { EventMap, MetabaseEvent } from "./events"

class MetabaseService {
  static MAX_BATCH_SIZE = 1
  batch: MetabaseEvent<keyof EventMap>[] = []
  userId?: string

  constructor(userId?: string) {
    this.updateUserId(userId)
  }

  getRandomEventId() {
    const min = 1_000_000_000
    const max = 10_000_000_000
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  updateUserId(userId?: string) {
    this.userId = userId
  }

  private clearBatch() {
    this.batch = []
  }

  private getConvertedBatchData() {
    let result = ""

    const platform = detectPlatform() || ""
    const browser = detectBrowser() || ""

    this.batch.forEach(event => {
      if (result) {
        result += "\n"
      }

      const eventItem = [event.name, this.userId, event.eventId, event.time, event.params, platform, browser]

      result += JSON.stringify(eventItem)
    })

    return result
  }

  private async sendBatch() {
    try {
      await api.metabase.sendBatch(this.getConvertedBatchData())
    } catch (err) {
      console.log(err)
    }

    this.clearBatch()
  }

  logEvent<K extends keyof EventMap>(event: K, params: EventMap[K]) {
    this.batch.push({
      name: event,
      targetId: this.userId,
      time: new Date().getTime(),
      eventId: this.getRandomEventId(),
      params,
    })

    if (this.batch.length === MetabaseService.MAX_BATCH_SIZE) {
      this.sendBatch()
    }
  }
}

export const metabaseService = new MetabaseService()

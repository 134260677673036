import React from "react"

export function FacebookSingleIcon({ fill = "white", h = 16, w = 16 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.375 3H4.125C3.82663 3 3.54048 3.11853 3.3295 3.3295C3.11853 3.54048 3 3.82663 3 4.125V12.375C3 12.6734 3.11853 12.9595 3.3295 13.1705C3.54048 13.3815 3.82663 13.5 4.125 13.5H7.3418V9.93023H5.86523V8.25H7.3418V6.96938C7.3418 5.51273 8.20898 4.70812 9.53719 4.70812C10.1733 4.70812 10.8384 4.82156 10.8384 4.82156V6.25125H10.1055C9.38344 6.25125 9.1582 6.69937 9.1582 7.15898V8.25H10.7702L10.5124 9.93023H9.1582V13.5H12.375C12.6734 13.5 12.9595 13.3815 13.1705 13.1705C13.3815 12.9595 13.5 12.6734 13.5 12.375V4.125C13.5 3.82663 13.3815 3.54048 13.1705 3.3295C12.9595 3.11853 12.6734 3 12.375 3Z"
        fill={fill}
      />
    </svg>
  )
}

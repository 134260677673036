import axios from "axios"

import type { Pool } from "../../../types/pool"
import { getApiBaseUrl, returnIf200 } from "../utils"

export type CreatePoolResponseData = Pool
export type CreatePoolRequestData = {
  fee: number
  image?: string
  description: string
  interval: number
  name: string
  percent: number
  type: "alliance"
  unit_type: "bot" | "ship" | string
}

export const createPool = async (token: string, data: CreatePoolRequestData) => {
  const res = await axios.post<CreatePoolResponseData>(`${getApiBaseUrl()}/rent_pools`, data, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

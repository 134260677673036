import { getMy } from "./my"
import { getTop100 } from "./top-100"
import { getTopAlliances } from "./top-alliances"
import { getTopPlayers } from "./top-players"

const leaderboard = {
  getTop100,
  getMy,
  getTopPlayers,
  getTopAlliances,
}

export default leaderboard

import axios from "axios"

import type { Alliance } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type CreateAllianceResponseData = Alliance
export type CreateAllianceRequestData = {
  name: string
  tag: string
}

export const createAlliance = async (token: string, data: CreateAllianceRequestData) => {
  const res = await axios.post<CreateAllianceResponseData>(
    `${getApiBaseUrl()}/admin/alliances`,
    { alliance: data },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

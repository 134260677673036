import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type RevealShipResponseData = {
  message: {
    owner: string
    seed: number
  }
  signature: string
}

export const getRevealData = async (token: string) => {
  const res = await axios.get<RevealShipResponseData>(`${getApiBaseUrl()}/ships/reveal`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

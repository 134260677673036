import axios from "axios"

import type { RentItem } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../utils"

type GetMyRentItemResponseData = RentItem

export const getMyRentItem = async (token: string, entityTokenId: number, type: "bot" | "ship") => {
  const res = await axios.get<GetMyRentItemResponseData>(
    `${getApiBaseUrl()}/rent_pools/unit_data?entity_id=${entityTokenId}&entity_type=${type}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

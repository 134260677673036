import { isProd } from "config/base"

import { amplitudeService } from "./amplitude"
import type { EventMap } from "./events"
import { generateGuid } from "./generate-guid"
import { metabaseService } from "./metabase"

class AmplitudeMetabaseService {
  logEvent = <K extends keyof EventMap>(event: K, value: EventMap[K]) => {
    console.log("--event", event as string, value)

    if (!amplitudeService.instance.getUserId()) {
      amplitudeService.setUserId(generateGuid())
    }

    amplitudeService.logEvent(event, value)

    if (isProd) {
      if (!metabaseService.userId) {
        metabaseService.updateUserId(amplitudeService.instance.getUserId())
      }

      metabaseService.logEvent(event, value)
    }
  }

  updateUserId(userId?: string) {
    userId && metabaseService.updateUserId(userId)
    userId && amplitudeService.setUserId(userId)
  }
}

export const amplitudeMetabaseService = new AmplitudeMetabaseService()

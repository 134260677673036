import axios from "axios"

import type { Pageable } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"
import type { MarketplaceBotInfo } from "./domain"

export type GetMarketplaceBotsResponse = Pageable<MarketplaceBotInfo[]>

export const getMarketplaceBots = async (queryParams = ""): Promise<GetMarketplaceBotsResponse | null> => {
  const res = await axios.get<GetMarketplaceBotsResponse>(`${getApiBaseUrl()}/marketplace/bots${queryParams}`)
  return returnIf200(res)
}

import axios from "axios"

import type { MetricsData } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = MetricsData & {
  email: string
  message: string
  nonce: string
  signature: string
}

type ResponseData = {
  bio: string
  email: string
  external_links: []
  id: string
  nickname: string
  profile_picture: string
  social_networks: string
  wallet: string
}

export const registerWithWallet = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/register`, regBody)
  return returnIf200(res)
}

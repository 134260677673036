import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type RentTeamResponseData = BotTeam

export const rentDemoTeam = async (token: string) => {
  const res = await axios.post<RentTeamResponseData>(`${getApiBaseUrl()}/free_pools/team/rent`, null, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

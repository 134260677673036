import axios from "axios"
import type { Bot } from "types/bot"
import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetBotResponseData = {
  bot: Bot
  kids: Bot[]
}

export const getBot = async (id: number) => {
  const res = await axios.get<GetBotResponseData>(`${getApiBaseUrl()}/bot/${id}`)
  return returnIf200(res)
}

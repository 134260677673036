import axios from "axios"

import type { AllianceApplication } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type ApproveApplicationResponseData = AllianceApplication

export const approveApplication = async (token: string, applicationId: string) => {
  const res = await axios.post<ApproveApplicationResponseData>(
    `${getApiBaseUrl()}/admin/alliances/applications/${applicationId}`,
    {},
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import React from "react"

export function MediumSingleIcon({ fill = "white", h = 16, w = 16 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.23047 4C2.89405 4 1 5.90713 1 8.25948C1 10.6118 2.8942 12.519 5.23047 12.519C7.56674 12.519 9.46094 10.612 9.46094 8.25948C9.46094 5.90699 7.56698 4 5.23047 4ZM11.9863 4.24952C10.8181 4.24952 9.87121 6.04527 9.87121 8.25955C9.87121 10.4738 10.8182 12.2697 11.9865 12.2697C13.1547 12.2697 14.1017 10.474 14.1017 8.25955H14.1016C14.1016 6.04464 13.1547 4.24952 11.9864 4.24952H11.9863ZM15.256 4.6672C14.8452 4.6672 14.5122 6.27566 14.5122 8.25955C14.5122 10.2434 14.845 11.8519 15.256 11.8519C15.667 11.8519 16 10.243 16 8.25948C16 6.27552 15.6668 4.6672 15.256 4.6672H15.256Z"
        fill={fill}
      />
    </svg>
  )
}

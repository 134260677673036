import axios from "axios"

import type { PageableBots } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetBotsByAccountProps = PageableBots

export const getBotsByAccount = async (id: string, queryParams = "") => {
  const res = await axios.get<GetBotsByAccountProps>(`${getApiBaseUrl()}/bots/by_account/${id}${queryParams}`)
  return returnIf200(res)
}

import { useRouter } from "next/router"
import { useMemo } from "react"
import { trackFooterLogoClick, trackHeaderLinkClick } from "services/analytics"

import { Link } from "../link"
import type { LogoTextProps } from "../logo-text"
import { LogoText } from "../logo-text"

type LogoTextUrlProps = LogoTextProps & {
  place?: "header" | "footer"
}

export function LogoTextUrl({ place, showLogoName = true }: LogoTextUrlProps) {
  const { pathname } = useRouter()

  const href = useMemo(() => {
    return pathname === "/landing" ? "/landing" : "/"
  }, [pathname])

  const handleClick = () => {
    if (place === "header") return trackHeaderLinkClick("home")
    if (place === "footer") return trackFooterLogoClick()
  }

  return (
    <Link href={href} withRedirect onClick={handleClick}>
      <LogoText showLogoName={showLogoName} />
    </Link>
  )
}

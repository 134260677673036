import axios from "axios"

import type { ShipTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type CreateShipTeamResponseData = ShipTeam

export const createTeam = async ({ token, ids, name }: { token: string; ids: number[]; name: string }) => {
  const res = await axios.post<CreateShipTeamResponseData>(
    `${getApiBaseUrl()}/ship_teams`,
    { ship_ids: ids, name },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

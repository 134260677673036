import { getBotsByAccount } from "./by-account"
import { getBot } from "./get-bot"
import { getMyBots } from "./get-my-bots"
import { getRevealData } from "./get-reveal-data"

const bots = {
  getBot,
  getMyBots,
  getBotsByAccount,
  getRevealData,
}

export default bots

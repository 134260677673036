import { createTeam } from "./create"
import { deleteTeam } from "./delete"
import { disbandTeam } from "./disband"
import { myTeams } from "./my"
import { showTeam } from "./show"

const botTeams = {
  myTeams,
  createTeam,
  showTeam,
  disbandTeam,
  deleteTeam,
}

export default botTeams

import axios from "axios"

import type { Activity, Alliance, AllianceApplication, Member } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetMyAllianceResponseData = {
  alliance: Alliance
  activity?: Activity
  applications?: AllianceApplication[]
  members: Member[]
}

export const getMyAlliance = async (token: string) => {
  const res = await axios.get<GetMyAllianceResponseData>(`${getApiBaseUrl()}/my/alliance`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import type { AxiosError, AxiosResponse } from "axios"
import axios from "axios"

import { isProd, isSandbox } from "config/base"

export const getApiBaseUrl = () => {
  if (isProd) {
    return "https://api.cryptobots.me/api/v1"
  }
  if (isSandbox) {
    return "https://api-sandbox.botsbits.io/api/v1"
  }
  return "https://api-staging.botsbits.io/api/v1"
}

export const returnIf200 = <T>(res: AxiosResponse<T>): T | null => {
  if (res.status >= 200 && res.status < 300) {
    return res.data
  }
  return null
}

type dataErrorType = {
  details: string | Record<string, string>
  error: string
  code: number
  status: number
}

export const handleError = (
  err: unknown,
  axiosErrorHandler?: (error?: dataErrorType) => void,
  nativeErrorHandler?: (error: Error) => void,
) => {
  const error = err as Error | AxiosError
  if (axios.isAxiosError(error)) {
    const axiosError = { ...error.response?.data?.error, status: error.response?.status }
    axiosErrorHandler?.(axiosError)
  } else {
    nativeErrorHandler?.(error)
  }
}

import React from "react"

export function Twitter({
  fill = "white",
  h = 28,
  w = 28,
  className = "",
}: {
  fill?: string
  h?: number
  w?: number
  className?: string
}) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 30 30" className={className}>
      <g>
        <path
          fill={fill}
          d="M15 0C7.26801 0 1 6.26801 1 14C1 21.732 7.26801 28 15 28C22.732 28 29 21.732 29 14C29 6.26801 22.732 0 15 0ZM14.6101 11.8638L14.5807 11.3794C14.4926 10.1238 15.2662 8.97699 16.4903 8.5321C16.9407 8.37392 17.7046 8.35415 18.204 8.49256C18.3999 8.55188 18.772 8.7496 19.0364 8.92756L19.5162 9.25381L20.045 9.08574C20.3388 8.99676 20.7305 8.84847 20.9068 8.7496C21.0733 8.66063 21.2202 8.61119 21.2202 8.64085C21.2202 8.80892 20.8578 9.38233 20.5543 9.69869C20.143 10.1436 20.2605 10.1831 21.0929 9.88653C21.5923 9.71847 21.6021 9.71847 21.5041 9.90631C21.4454 10.0052 21.1418 10.3512 20.8187 10.6676C20.2703 11.2113 20.2409 11.2706 20.2409 11.7254C20.2409 12.4273 19.9079 13.8905 19.575 14.6913C18.958 16.194 17.636 17.7462 16.314 18.5272C14.4534 19.6246 11.9758 19.9014 9.88999 19.2588C9.19471 19.0413 8 18.4877 8 18.3888C8 18.3591 8.36233 18.3196 8.803 18.3097C9.72352 18.2899 10.644 18.0329 11.4274 17.5781L11.9563 17.2618L11.3491 17.0541C10.4873 16.7576 9.71372 16.0754 9.51787 15.4328C9.45911 15.2252 9.4787 15.2153 10.0271 15.2153L10.5951 15.2054L10.1152 14.978C9.54725 14.6913 9.02823 14.2069 8.77362 13.7126C8.58756 13.3566 8.35254 12.457 8.42109 12.3878C8.44067 12.3581 8.64632 12.4174 8.88134 12.4965C9.55704 12.7437 9.64517 12.6844 9.25347 12.2691C8.51901 11.5178 8.29378 10.4006 8.64632 9.34278L8.81279 8.86824L9.45911 9.51085C10.7811 10.806 12.3382 11.5771 14.1204 11.8045L14.6101 11.8638Z"
        />
      </g>
    </svg>
  )
}

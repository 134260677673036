import { ChainId } from "@usedapp/core"
import getConfig from "next/config"

const { publicRuntimeConfig } = getConfig()

export const devEnv = publicRuntimeConfig.DEV_ENV || "dev"

export const network = publicRuntimeConfig.NETWORK
export const isMainnet = network === "mainnet"
export const isMumbai = network === "mumbai"
export const isPolygon = network === "polygon"

export const isDev = devEnv === "dev"
export const isStaging = devEnv === "staging"
export const isSandbox = devEnv === "sandbox"
export const isProd = devEnv === "prod"

export const FB_PIXEL_ID = "2030057823840789"
export const GA_TRACKING_ID = "UA-212576813-1"

export const wertPartnerId = isPolygon ? "01GWH4BWWK094CA7WJA0PKAN5H" : "01GQPSD5R76MZ2QM47RADK787R"
export const wertOrigin = isPolygon ? "https://widget.wert.io" : "https://sandbox.wert.io"

export const developerMode = publicRuntimeConfig.DEV_MODE === "true"
export const fakePwa = publicRuntimeConfig.FAKE_PWA === "true"

// @todo: del
export const tokenAddress = isMainnet
  ? "0x4dF461F8c22c0bfB40a51560597D5b946d869d5c"
  : "0xC396C29D2fB0B1B21af86D53E66F1512edf825e1"

export const infuraId = publicRuntimeConfig.infuraId

export const infuraRpcMap = {
  [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${infuraId}`,
  [ChainId.Mumbai]: `https://polygon-mumbai.infura.io/v3/${infuraId}`,
  [ChainId.Polygon]: `https://polygon-mainnet.infura.io/v3/${infuraId}`,
}

type Token = "MATIC" | "BITS"
export const primaryTokenName: Token = "MATIC"

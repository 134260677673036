import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  code: number
}

export const confirmEmailWithCode = async (token: string, regBody: RequestData) => {
  const res = await axios.post(`${getApiBaseUrl()}/account/verify`, regBody, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import { createTeam } from "./create"
import { deleteTeam } from "./delete"
import { disbandTeam } from "./disband"
import { myShipTeams } from "./my-ship-teams"
import { showTeam } from "./show"

const shipTeams = {
  myShipTeams,
  createTeam,
  showTeam,
  disbandTeam,
  deleteTeam,
}

export default shipTeams

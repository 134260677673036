import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetBotTeamsResponseData = Array<BotTeam>

export const myTeams = async (token: string) => {
  const res = await axios.get<GetBotTeamsResponseData>(`${getApiBaseUrl()}/bot_teams`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

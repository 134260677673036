import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"
import type { LeaderboardPlayer } from "./top-players"

export type LeaderboardGloryPlayer = Omit<LeaderboardPlayer, "points"> & { mmr: number }
export type GetTop100Data = {
  data: LeaderboardGloryPlayer[]
}

export const getTop100 = async () => {
  const res = await axios.get<GetTop100Data>(`${getApiBaseUrl()}/leaderboard/top100`)
  return returnIf200(res)
}

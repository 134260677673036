import { approveApplication } from "./approve-application"
import { createApplication } from "./create-application"
import { declineApplication } from "./decline-application"
import { getMyApplications } from "./get-my-applications"

const applications = {
  createApplication,
  approveApplication,
  declineApplication,
  getMyApplications,
}

export default applications

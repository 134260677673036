import applications from "./applications"
import { createAlliance } from "./create-alliance"
import { deleteAlliance } from "./delete-alliance"
import { getAlliance } from "./get-alliance"
import { getAlliances } from "./get-alliances"
import { getMyAlliance } from "./get-my-alliance"
import { getMyAllianceSign } from "./get-my-alliance-sign"
import { updateAlliance } from "./update-alliance"

const alliance = {
  createAlliance,
  updateAlliance,
  deleteAlliance,
  getMyAllianceSign,
  getMyAlliance,
  getAlliance,
  getAlliances,

  applications,
}

export default alliance

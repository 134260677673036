import axios from "axios"

import type { Alliance } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type CreateAllianceResponseData = Alliance

export const deleteAlliance = async (token: string, allianceId: string) => {
  const res = await axios.delete<CreateAllianceResponseData>(`${getApiBaseUrl()}/admin/alliances/${allianceId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import { getMarketplaceBot } from "./get-marketplace-bot"
import { getMarketplaceBots } from "./get-marketplace-bots"
import { getMarketplaceShip } from "./get-marketplace-ship"
import { getMarketplaceShips } from "./get-marketplace-ships"

const marketplace = {
  getMarketplaceBots,
  getMarketplaceShips,
  getMarketplaceShip,
  getMarketplaceBot,
}

export default marketplace

import axios from "axios"

import type { PoolApplication } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type GetPoolApplicationsResponseData = Array<PoolApplication>

export const getPoolApplications = async (token: string, poolId: string) => {
  const res = await axios.get<GetPoolApplicationsResponseData>(`${getApiBaseUrl()}/rent_pools/application/${poolId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

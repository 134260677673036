import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  email: string
  reset_token: string
}

type ResponseData = {
  expires_at?: string
  valid: boolean
}

export async function resetPasswordTokenVerify(regBody: RequestData) {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/reset_password/verify`, regBody)
  return returnIf200(res)
}

import { useRouter } from "next/router"
import React, { useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { api } from "services/api"
import type { ClientSettings } from "services/api/settings/get-settings"

import { useClientSettings } from "components/hooks/use-client-settings"

import { sleep } from "utils/sleep"
import { year } from "utils/time"

import type { Account } from "types/account"

export const COOKIE_TOKEN_NAME = "token"

export type AuthContextType = {
  token: string | null
  account?: Account | null
  settings?: ClientSettings | null
  login: (authToken: string) => Promise<void>
  logout: () => void
  updateAccount: (account?: Account | null) => Promise<Account | undefined | null>
}

export const AuthContext = React.createContext<AuthContextType | null>(null)
export const AuthProvider = ({ children, defaultToken }: { children: React.ReactNode; defaultToken: string }) => {
  const router = useRouter()
  const [cookies, setCookie] = useCookies([COOKIE_TOKEN_NAME])
  const [token, setToken] = useState<string | null>(cookies[COOKIE_TOKEN_NAME] ?? defaultToken)
  const [account, setAccount] = useState<Account | null>()
  const settings = useClientSettings()

  const login = useCallback(
    async (authToken: string) => {
      setCookie(COOKIE_TOKEN_NAME, authToken, { path: "/", maxAge: year / 1000, sameSite: "lax", secure: true })
      setToken(authToken)
      await sleep(300)
    },
    [setCookie],
  )

  const logout = useCallback(async () => {
    setCookie(COOKIE_TOKEN_NAME, null, { path: "/", maxAge: 0 })
    await sleep(300)
    setToken(null)
    setAccount(null)
  }, [setCookie])

  const updateAccount = useCallback(
    async (data?: Account | null) => {
      if (!data) {
        if (token) {
          try {
            data = await api.account.getMyAccount(token)
          } catch (error) {
            console.error(error)
          }
        }
      }

      if (JSON.stringify(data) !== JSON.stringify(account)) {
        await settings.updateSettings()

        setAccount(data)
      }

      return data
    },
    [account, token, settings],
  )

  useEffect(() => {
    updateAccount()
  }, [token, updateAccount, router.route])

  return (
    <AuthContext.Provider
      value={{
        token,
        account,
        updateAccount,
        login,
        logout,
        settings,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

import type { RentItem } from "types/pool"

import { getMyRentItem } from "./get-my-rent-item"

type GetMyRentListResponseData = Array<RentItem>

export const getMyRentList = async (
  token: string,
  entityTokenIds: Array<number>,
  type: "bot" | "ship",
): Promise<GetMyRentListResponseData> => {
  const promises = entityTokenIds.map(entityTokenId => getMyRentItem(token, entityTokenId, type))
  const responses = await Promise.all(promises)
  const rentList: Array<RentItem> = []

  responses.forEach(response => {
    if (response) {
      rentList.push(response)
    }
  })

  return rentList
}

import { isProd, isSandbox } from "./base"

export enum Links {
  ALLIANCE = "/alliance",
  ALLIANCE_EDIT = "/alliance/edit",
  ALLIANCE_SEARCH = "/alliance/search",
  AUTH = "/auth",
  AUTH_LOGIN = "/auth?auth_type=login",
  REQUEST_RESET_PASSWORD = "/request-reset-password",
  BATTLE_HOST = "/battle",
  BATTLE_SELECT = "/battle-select",
  BOT = "/bot",
  BOTS = "/bots",
  BOTS_3D = "/bots-3d",
  BRIDGE = "/bridge",
  BUY_BOTS = "/buy-bots",
  BUY_BOTS_COMPLETE = "/buy-bots-complete",
  CLASSIC = "/classic",
  COMPANION = "/companion",
  CONTACT = "/contact",
  COOKIES = "/cookies",
  CREATE_BOT_TEAM = "/teams/bots/create",
  CREATE_SHIP_TEAM = "/teams/ships/create",
  DISCLAIMER = "/disclaimer",
  DISCOVERY = "/game",
  FAQ = "/faq",
  FUSE = "/fuse",
  GAME = "/game",
  DEMO_TEAM = "/show-demo-team",
  NEW_DEMO_TEAM = "/show-new-demo-team",
  INVENTORY = "/inventory",
  LANDS = "/lands",
  LEADERBOARD = "/leaderboard",
  MAINTENANCE = "/maintenance",
  MAIN_PAGE = "/",
  MANUFACTURING = "/manufacturing",
  MARKETPLACE = "/marketplace",
  MY_POOL = "/pool/my-pool",
  MY_RENT_LIST = "/pool/my-rent",
  POOL = "/pool",
  POOL_EDIT = "/pool/edit",
  POOL_RENT_OPTIONS = "/pool/rent-options",
  PREREGISTRATION = "/pre-registration",
  PRIVACY = "/privacy",
  PROFILE = "/profile",
  REFERRALS_GUIDE = "/referrals-guide",
  REWARDS = "/rewards",
  SEARCH_POOLS = "/pool/search",
  STAKE = "/stake",
  STAKE_PWA = "/stake-pwa",
  STAKING = "/staking",
  SWAP = "/swap",
  TEAM = "/team",
  TEAMS = "/teams",
  TOP_ALLIANCES = "/top-alliances",
  TOP_PLAYERS = "/top-players",
  TOURNAMENT = "/tournament",
  WRAP = "/wrap",
  LINK_WALLET = "/link-wallet",
  CREATE_WALLET = "/link-wallet?action=create",
  IMPORT_WALLET = "/link-wallet?action=import",
  WALLET_DASHBOARD = "/wallet-dashboard",
  WALLET_DASHBOARD_DEPOSIT_OIL = "/wallet-dashboard?deposit_token=Oil",
  WALLET_DASHBOARD_DEPOSIT_BITS = "/wallet-dashboard?deposit_token=Bit",
  WALLET_DASHBOARD_DEPOSIT_MATIC = "/wallet-dashboard?deposit_token=Matic",
  WALLET_DASHBOARD_DEPOSIT_MATIC_WITH_WERT = "/wallet-dashboard?deposit_token=Matic&value=5",
  WALLET_DASHBOARD_SWAP_OIL = "/wallet-dashboard?swap_token=Oil",
  WALLET_DASHBOARD_SWAP_BITS = "/wallet-dashboard?swap_token=Bit",
  INVITATION_TO_CREATE_WALLET = "/invitation-to-create-wallet",
  INVITATION_TO_TOP_UP_WALLET = "/invitation-to-top-up-wallet",
  INVITATION_TO_REPAIR_BOTS = "/invitation-to-repair-bots",

  ARENAS = "https://gitbook.cryptobots.me/cryptobots/gameplay-journey/stage-3-core-gameplay#arenas",
  COMPANIONS = "https://x2y2.io/collection/cbcompanions/items",
  DEMO = "https://demo.cryptobots.me/",
  DISCORD = "https://discord.com/invite/nzZrUAcUcK",
  DISCORD1 = "https://discord.gg/AquRPHkEth",
  DISCORD2 = "https://discord.gg/V28wrqqA3C",
  DISCORD3 = "https://discord.gg/wvBbewGYrA",
  DISCORD_BANNER = "https://discord.com/invite/HhhV6GH7JG",
  DISCORD_INVITE = "https://discord.com/invite/wRSJsauXxH",
  FB = "https://www.facebook.com/cryptobotsgame",
  HOW_TO_START = "https://gitbook.cryptobots.me/cryptobots/how-to-start-playing-cryptobots-game",
  INSTAGRAM = "https://www.instagram.com/cryptobots.me/",
  LINKEDIN = "https://www.linkedin.com/company/crypto-bots",
  LOOKS_RARE_GENESIS = "https://looksrare.org/ru/collections/0x4dF461F8c22c0bfB40a51560597D5b946d869d5c",
  LP_LOCK = "https://gitbook.cryptobots.me/cryptobots/economy/lp-staking#liquidity-provider-lp-staking-rewards",
  MEDIUM = "https://medium.com/@cryptobots",
  METAMASK = "https://metamask.io/",
  OPENSEA_CLASSIC = "https://opensea.io/collection/cryptobots",
  OPENSEA_GENESIS = "https://opensea.io/collection/cryptobotsgame",
  OPENSEA_WRAPP = "https://opensea.io/collection/wrapped-cryptobots",
  PLANETS = "https://gitbook.cryptobots.me/cryptobots/in-game-economy/economy-actors#planets",
  PLAYER_ALLIANCES = "https://gitbook.cryptobots.me/cryptobots/gameplay-journey/stage-3-core-gameplay#player-alliances",
  PROD_TESTNET = "https://rinkeby.cryptobots.me/",
  READ_SHIPS = "https://medium.com/@cryptobots/spaceships-utility-and-manufacturing-90e0001c7d09",
  RENTS_AND_SCHOLARSHIPS = "https://gitbook.cryptobots.me/cryptobots/gameplay-journey/stage-1-basics#rents-and-scholarships",
  ROADMAP = "https://gitbook.cryptobots.me/cryptobots/roadmap",
  STAKING_OLD = "https://old.cryptobots.me/staking",
  TELEGRAM = "https://t.me/cryptobotsgame",
  TRANSAK = "https://global.transak.com/?apiKey=25ac1309-a49b-4411-b20e-5e56c61a5b1c&hostURL=https%3A%2F%cryptobots.me&defaultCryptoCurrency=MATIC&networks=polygon&walletAddress=",
  TWITTER = "https://twitter.com/cryptobots_game",
  VOTE_LOCK = "https://gitbook.cryptobots.me/cryptobots/economy/governance-token-bits/vote-locking",
  WHITEPAPPER = "https://gitbook.cryptobots.me/cryptobots/",
  X2Y2_GENESIS = "https://x2y2.io/collection/cryptobotsgame/items",
}

export const WSS_BATTLE = isProd
  ? "wss://battles.cryptobots.me/socket"
  : isSandbox
  ? "wss://battles-sandbox.botsbits.io/socket"
  : "wss://battles-staging.botsbits.io/socket"

import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type DeleteTeamResponseData = BotTeam

export const deleteTeam = async (token: string, id: string) => {
  const res = await axios.delete<DeleteTeamResponseData>(`${getApiBaseUrl()}/bot_teams/${id}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

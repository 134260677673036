import { useRouter } from "next/router"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Links } from "config/links"

import { useMounted } from "components/hooks/use-mounted"
import useOnClickOutside from "components/hooks/use-on-click-outside"
import { useTailwindBreakpoints } from "components/hooks/use-tailwind-breakpoints"

import { GradientButton } from "components/shared/gradient-button/gradient-button"
import { Close3 } from "components/shared/icons/close-3"
import { Button } from "components/shared/landing/button"

import { useNoScroll } from "../hooks/use-no-scroll"
import { Box } from "../shared/box"
import { Menu } from "../shared/icons/menu"
import { Link } from "../shared/link"
import { LogoTextUrl } from "../shared/logo-text-url"
import { DesktopSubmenu } from "./desktop-submenu"
import type { ActionButton, SubMenu } from "./domain"
import styles from "./header.module.scss"
import { MenuItemsList } from "./menu-items-list"
import { MobileMenu } from "./mobile-menu"
import { menuItems } from "./utils"

type HeaderProps = {
  fixed?: boolean
  showMenu?: boolean
  showMobileMenu?: boolean
  showLogoName?: boolean
  actionBtn?: ActionButton
  alwaysShowActionButton?: boolean
  className?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Header({
  fixed = false,
  showMenu = true,
  showMobileMenu = true,
  showLogoName = true,
  // @todo remove after lp merge if don't needed
  // alwaysShowActionButton = false,
  className,
}: HeaderProps) {
  const { pathname } = useRouter()
  const [mobileMenuIsVisible, setMobileMenuVisible] = useState(false)
  const { setNoScroll } = useNoScroll()
  const { isMobile, lg } = useTailwindBreakpoints()
  const mounted = useMounted()

  const [openedSubMenuLabels, setOpenedSubMenuLabels] = useState<string[]>([])

  const toggleSubMenu = (label: string) => {
    let newSubMenus = openedSubMenuLabels
    if (!isMobile) {
      // only one subMenu could be open on desktop
      newSubMenus = newSubMenus.filter(openedSubMenuLabel => openedSubMenuLabel === label)
    }

    if (openedSubMenuLabels.includes(label)) {
      newSubMenus = newSubMenus.filter(openedSubMenuLabel => openedSubMenuLabel !== label)
    } else {
      newSubMenus = [...newSubMenus, label]
    }

    setOpenedSubMenuLabels(newSubMenus)
  }

  const openMobileMenu = useCallback(() => {
    setMobileMenuVisible(true)
    setNoScroll(true)
  }, [setNoScroll])

  const hideMobileMenu = useCallback(() => {
    setMobileMenuVisible(false)
    setNoScroll(false)
  }, [setNoScroll])

  useEffect(() => {
    if (!isMobile) {
      setOpenedSubMenuLabels([])
    }
  }, [isMobile])

  useEffect(() => {
    hideMobileMenu()
    setOpenedSubMenuLabels([])
  }, [hideMobileMenu, pathname, setNoScroll])

  const updatedMenuItems = useMemo(() => {
    if (!mounted) return []

    let filteredMenuItems = menuItems

    if (pathname === "/landing") {
      filteredMenuItems = filteredMenuItems.filter(item => item.label !== "Staking")
    }

    if (!lg) {
      return filteredMenuItems
    }

    return filteredMenuItems
      .filter(menuItem => "linkColumns" in menuItem)
      .concat([
        {
          label: "More",
          linkColumns: [filteredMenuItems.filter(menuItem => !("linkColumns" in menuItem))],
          bgClassName: "bg-[url(/images/pages/header/bg-more.png)]",
        },
      ])
  }, [pathname, lg, mounted])

  const currentDesktopSubMenu = useMemo(() => {
    return openedSubMenuLabels.length > 0 && !isMobile
      ? (updatedMenuItems.find(menuItem => menuItem.label === openedSubMenuLabels[0]) as SubMenu)
      : null
  }, [openedSubMenuLabels, isMobile, updatedMenuItems])

  const toggleMobileMenu = useCallback(() => {
    if (mobileMenuIsVisible) {
      hideMobileMenu()
    } else {
      openMobileMenu()
    }
  }, [hideMobileMenu, mobileMenuIsVisible, openMobileMenu])

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(() => {
    if (isMobile) {
      hideMobileMenu()
    } else {
      setOpenedSubMenuLabels([])
    }
  }, [isMobile, hideMobileMenu])

  useOnClickOutside(ref, handleClickOutside)

  return (
    <div ref={ref}>
      <Box
        row
        center
        className={`z-40 w-full bg-[#3E0891] backdrop-blur-2xl ${
          fixed ? "drop-shadow-xl fixed left-0 top-0" : "absolute"
        } ${className}`}
      >
        <Box
          row
          center
          className={`h-[calc(60px+1.5rem)] w-full ${
            currentDesktopSubMenu || mobileMenuIsVisible ? styles["header__opened"] : styles.header
          }`}
        >
          <Box
            row
            center
            className={`h-full w-full max-w-[1200px] justify-between px-4 text-white sm:gap-4 md:px-12 lg:gap-0`}
          >
            <Box row className="h-full w-full items-center">
              <LogoTextUrl place="header" showLogoName={showLogoName} />

              {showMenu && !isMobile && (
                <MenuItemsList
                  toggleSubMenu={toggleSubMenu}
                  menuItems={updatedMenuItems}
                  openedSubMenuLabels={openedSubMenuLabels}
                />
              )}
            </Box>

            <Box row className="hidden w-auto items-center sm:flex">
              <Link href={Links.GAME}>
                <GradientButton className="w-[160px] py-[6px]">Play for free</GradientButton>
              </Link>
            </Box>

            <Box row center className="gap-6">
              {showMobileMenu && (
                <div className={"flex cursor-pointer lg:hidden"}>
                  <Button
                    noPadding
                    noVolume
                    type="transparent"
                    className={`w-[120px] border-2 border-white/25 py-[4px]  text-[14px] uppercase leading-[20px] text-white`}
                    onClick={toggleMobileMenu}
                  >
                    <Box row className="items-center gap-2 uppercase">
                      {mobileMenuIsVisible && (
                        <>
                          close <Close3 h={18} w={18} />
                        </>
                      )}
                      {!mobileMenuIsVisible && (
                        <>
                          menu <Menu h={12} w={12} />
                        </>
                      )}
                    </Box>
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </Box>

        {currentDesktopSubMenu && <DesktopSubmenu currentDesktopSubMenu={currentDesktopSubMenu} />}
      </Box>

      {mobileMenuIsVisible && (
        <MobileMenu
          openedSubMenuLabels={openedSubMenuLabels}
          toggleSubMenu={toggleSubMenu}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
    </div>
  )
}

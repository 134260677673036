import axios from "axios"

import type { PoolApplication } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type declineApplicationResponseData = PoolApplication

export const deleteApplication = async (token: string, applicationId: string) => {
  const res = await axios.post<declineApplicationResponseData>(
    `${getApiBaseUrl()}/rent_pools/applications/${applicationId}/remove`,
    {},
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import type { ReactNode } from "react"
import React from "react"

import { Button } from "components/shared/landing/button"

import { Box } from "../box"
import { Rotating } from "../icons/rotating"
import styles from "./gradient-button.module.scss"

type GradientButtonType = "primary-heatmap" | "primary-purple" | "primary-deep-blue"

type GradientButtonProps = {
  onClick?: () => void
  children: ReactNode
  className?: string
  type?: GradientButtonType
  disabled?: boolean
  loading?: boolean
  as?: "button" | "div"
}

export function GradientButton({
  children,
  onClick,
  disabled,
  loading,
  className = "w-[140px] py-[6px]",
  type = "primary-deep-blue",
  as = "button",
}: GradientButtonProps) {
  const buttonClassName = `gradient-button__${type}`

  return (
    <Button
      noPadding
      noVolume
      disabled={disabled || loading}
      as={as}
      type="transparent"
      className={`uppercase ${styles[buttonClassName]} text-[14px] leading-[20px] text-white ${className} ${
        disabled && styles["gradient-button__disabled"]
      }`}
      onClick={disabled || loading ? undefined : onClick}
    >
      {loading ? (
        <Box row center>
          <Rotating className="animate-spin w-[1rem] mr-2" /> {children}
        </Box>
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}

import axios from "axios"

import type { ReadyReward } from "types/reward"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type ClaimResponseDataType = {
  message: {
    addr: string
    id: string
    parts: Array<{
      amount: string
      id: string
      name: "BIT" | "OIL"
    }>
  }
  reward: {
    id: string
    inserted_at: string
    rewards: ReadyReward[]
    status: "unclaimed"
    updated_at: string
  }
  signature: string
}

export const claimRewards = async (token: string) => {
  const res = await axios.post<ClaimResponseDataType>(`${getApiBaseUrl()}/rewards`, null, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

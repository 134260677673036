import React from "react"

export function TwitterSingle({ fill = "white", h = 24, w = 25 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4638_22771)">
        <path
          d="M19.3997 8.33381C19.4112 8.4937 19.4112 8.65363 19.4112 8.81352C19.4112 13.6904 15.6992 19.3097 8.91497 19.3097C6.82486 19.3097 4.88325 18.7043 3.25 17.6536C3.54696 17.6879 3.83247 17.6993 4.14086 17.6993C5.86545 17.6993 7.45304 17.1168 8.7208 16.1232C7.09898 16.0889 5.73984 15.0267 5.27155 13.5648C5.5 13.599 5.72841 13.6219 5.96828 13.6219C6.29949 13.6219 6.63073 13.5762 6.93909 13.4963C5.24874 13.1536 3.98093 11.6688 3.98093 9.8757V9.83003C4.47203 10.1041 5.04314 10.2755 5.64843 10.2983C4.65477 9.63582 4.00379 8.50513 4.00379 7.22593C4.00379 6.54067 4.18649 5.9125 4.50631 5.36427C6.3223 7.60284 9.05201 9.06475 12.1129 9.22467C12.0558 8.95056 12.0215 8.66505 12.0215 8.37952C12.0215 6.3465 13.6662 4.69043 15.7106 4.69043C16.7728 4.69043 17.7322 5.13586 18.406 5.8554C19.2398 5.69551 20.0393 5.38712 20.7474 4.96454C20.4733 5.82116 19.8908 6.5407 19.1256 6.99752C19.868 6.91761 20.5875 6.71198 21.2499 6.42648C20.7475 7.15741 20.1193 7.8084 19.3997 8.33381V8.33381Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4638_22771">
          <rect width="18" height="18" fill="white" transform="translate(3.25 3)" />
        </clipPath>
      </defs>
    </svg>
  )
}

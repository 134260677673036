import { useRouter } from "next/router"
import { trackHeaderLinkClick } from "services/analytics"

import { Box } from "components/shared/box"
import { ArrowDown } from "components/shared/icons/arrow-down"
import { LeftArrow } from "components/shared/icons/left-arrow"
import { Link } from "components/shared/link"

import { ActiveUnderline } from "../shared/active-underline"
import type { MenuLink, SubMenu } from "./domain"
import styles from "./header.module.scss"

type MenuItemsListProps = {
  menuItems: (MenuLink | SubMenu)[]
  openedSubMenuLabels: string[]
  toggleSubMenu: (label: string) => void
}

export function MenuItemsList({ menuItems, openedSubMenuLabels, toggleSubMenu }: MenuItemsListProps) {
  const { pathname } = useRouter()

  return (
    <Box row className="w-full h-full items-center justify-between mx-20 xl:mx-16">
      {menuItems.map((menuItem, idx) => {
        const isLink = "url" in menuItem
        const isOpened = openedSubMenuLabels.includes(menuItem.label)

        return isLink ? (
          <Link
            key={`${menuItem.label}-${idx}`}
            href={menuItem.url}
            target={menuItem.target}
            className="px-2 h-full uppercase text-sm hidden md:flex items-center font-bold relative"
            withRedirect
            onClick={() => {
              trackHeaderLinkClick(menuItem.eventName)
            }}
          >
            {menuItem.label}
            {pathname.toLowerCase() === menuItem.url.toLowerCase() && (
              <ActiveUnderline className="absolute bottom-0 left-0 right-0" />
            )}
          </Link>
        ) : (
          <Link
            onClick={() => toggleSubMenu(menuItem.label)}
            className="md:flex h-full"
            key={`${menuItem.label}-${idx}`}
          >
            <Box
              row
              className={`px-2 h-full uppercase text-sm hidden md:flex font-bold items-center gap-1 relative ${
                isOpened ? styles["header_active-item"] : ""
              }`}
            >
              {menuItem.label}

              <ArrowDown w={12} h={12} className={isOpened ? "" : "hidden"} />
              <Box center className={`w-[12px] ${isOpened ? "hidden" : ""}`}>
                <LeftArrow h={8} w={8} className="-rotate-90" />
              </Box>

              {isOpened && <ActiveUnderline className="absolute bottom-0 left-0 right-0" />}
            </Box>
          </Link>
        )
      })}
    </Box>
  )
}

import React from "react"

export function Medium({ fill = "white", h = 28, w = 28 }: { fill?: string; h?: number; w?: number }) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 22 22">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.23096 10.2305C1.23096 4.70762 5.70811 0.230469 11.231 0.230469C16.7538 0.230469 21.231 4.70762 21.231 10.2305C21.231 15.7533 16.7538 20.2305 11.231 20.2305C5.70811 20.2305 1.23096 15.7533 1.23096 10.2305ZM9.62282 7.53751L6.42115 5.95563C6.39375 5.94208 6.36764 5.93569 6.34405 5.93569C6.31044 5.93569 6.28201 5.94875 6.26201 5.97259C6.24243 5.9959 6.23096 6.0296 6.23096 6.07177V12.8628C6.23096 12.9784 6.31655 13.1152 6.42115 13.1669L9.37987 14.6287C9.42098 14.6491 9.46013 14.6587 9.49552 14.6587C9.59521 14.6587 9.66514 14.5825 9.66514 14.4545V7.6052C9.66514 7.57655 9.64876 7.55033 9.62282 7.53751ZM16.2479 14.6265L13.3729 13.206L16.5332 8.13131V14.4522C16.5332 14.6256 16.4048 14.704 16.2479 14.6265ZM9.96867 8.04582V11.5261L13.0994 13.0729L9.96867 8.04582ZM13.1944 5.90865L16.4579 7.52108C16.4986 7.54116 16.513 7.59133 16.4892 7.6296L13.2775 12.7868L11.0321 9.18115L13.0436 5.95113C13.065 5.91683 13.1045 5.89714 13.1442 5.89714C13.1453 5.89714 13.1463 5.89745 13.1474 5.89749C13.1635 5.89791 13.1796 5.90131 13.1944 5.90865Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

import axios from "axios"

import type { PoolApplication } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type CreateApplicationResponseData = PoolApplication
type CreateApplicationRequestData = {
  poolId: string
  entityId: string
}

export const createApplication = async (token: string, data: CreateApplicationRequestData) => {
  const res = await axios.post<CreateApplicationResponseData>(
    `${getApiBaseUrl()}/rent_pools/applications/${data.poolId}`,
    {
      unit_id: data.entityId,
    },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

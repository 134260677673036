import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  email: string
  password: string
  password_confirmation: string
  reset_token: string
}

export async function resetPassword(regBody: RequestData) {
  const res = await axios.post(`${getApiBaseUrl()}/reset_password`, regBody)
  return returnIf200(res)
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type ResponseData = {
  exists: boolean
}

export const exists = async (wallet: string) => {
  const res = await axios.get<ResponseData>(`${getApiBaseUrl()}/wallet/${wallet}/exists`)
  const result = returnIf200(res)
  return result?.exists
}

import axios from "axios"

import type { BotTeam } from "types/team"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type CreateTeamResponseData = BotTeam

export const createTeam = async ({ token, ids, name }: { token: string; ids: number[]; name: string }) => {
  const res = await axios.post<CreateTeamResponseData>(
    `${getApiBaseUrl()}/bot_teams`,
    { bot_ids: ids, name },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import { useMemo } from "react"

import { useWindowSize } from "./use-window-size"

export enum MinWidth {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  // can't start with number, so XL2 instead of XL2
  XL2 = 1536,
}

export enum MinHeight {
  SM = 420,
  MD = 768,
}

export function useTailwindBreakpoints() {
  const { width, height } = useWindowSize()
  const xs = width < MinWidth.SM
  const sm = width >= MinWidth.SM && width < MinWidth.MD
  const md = width >= MinWidth.MD && width < MinWidth.LG
  const lg = width >= MinWidth.LG && width < MinWidth.XL
  const xl = width >= MinWidth.XL && width < MinWidth.XL2
  const xl2 = width >= MinWidth.XL

  const isMobile = xs || sm || md
  const isLandscape = height < width
  const isMobileLandscape = isLandscape && height < MinHeight.MD

  return useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      xl2,
      isMobile,
      isLandscape,
      isMobileLandscape,
      width,
      height,
    }),
    [isLandscape, isMobile, isMobileLandscape, lg, md, sm, width, height, xl, xl2, xs],
  )
}

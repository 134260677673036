import { getBattleCounters } from "./get-battle-counters"
import { getBattleResult } from "./get-battle-result"
import { getBattlesHistory } from "./get-battles-history"

const battles = {
  getBattleResult,
  getBattlesHistory,
  getBattleCounters,
}

export default battles

import React from "react"

export function LogoDark({ h = 36, w = 36 }: { h?: number; w?: number }) {
  return (
    <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3612_981)">
        <path
          d="M37.865 17.865L22.135 2.13496C20.9559 0.955852 19.0441 0.955856 17.865 2.13496L2.13496 17.865C0.95585 19.0441 0.955847 20.9559 2.13496 22.135L17.865 37.865C19.0441 39.0441 20.9558 39.0441 22.135 37.865L37.865 22.135C39.0441 20.9559 39.0441 19.0441 37.865 17.865Z"
          fill="#FF46AA"
        />
        <path
          d="M25.4811 12.8116C23.9079 14.0154 21.982 14.6676 20.0011 14.6676C18.0202 14.6676 16.0944 14.0154 14.5212 12.8116C13.0155 13.9583 11.9075 15.5484 11.3532 17.358C10.7989 19.1677 10.8262 21.1056 11.4313 22.8988C12.0364 24.6921 13.1888 26.2504 14.7262 27.3542C16.2636 28.4579 18.1085 29.0517 20.0011 29.0517C21.8937 29.0517 23.7387 28.4579 25.2761 27.3542C26.8134 26.2504 27.9659 24.6921 28.571 22.8988C29.1761 21.1056 29.2034 19.1677 28.6491 17.358C28.0948 15.5484 26.9867 13.9583 25.4811 12.8116Z"
          fill="white"
        />
        <path
          d="M22.1287 19.9808C21.6534 19.99 21.1887 19.8398 20.8087 19.5541C20.4287 19.2685 20.1554 18.8638 20.0321 18.4046C19.9089 17.9455 19.943 17.4583 20.1289 17.0208C20.3149 16.5832 20.642 16.2206 21.058 15.9906C20.7137 15.8947 20.358 15.8455 20.0006 15.8443C19.1877 15.8443 18.3931 16.0853 17.7172 16.5369C17.0413 16.9885 16.5146 17.6304 16.2035 18.3814C15.8924 19.1324 15.811 19.9588 15.9696 20.7561C16.1282 21.5533 16.5196 22.2856 17.0944 22.8604C17.6692 23.4352 18.4015 23.8266 19.1988 23.9852C19.996 24.1438 20.8224 24.0624 21.5734 23.7514C22.3244 23.4403 22.9663 22.9135 23.4179 22.2376C23.8695 21.5617 24.1106 20.7671 24.1106 19.9542C24.1094 19.5968 24.0602 19.2411 23.9643 18.8968C23.7812 19.2234 23.5149 19.4957 23.1925 19.6861C22.8702 19.8765 22.5031 19.9782 22.1287 19.9808Z"
          fill="#413245"
        />
        <path d="M4.63065 2.28354L2.36401 4.55017L6.1919 8.37806L8.45854 6.11142L4.63065 2.28354Z" fill="white" />
        <path d="M31.5356 6.11255L33.8022 8.37918L37.6301 4.5513L35.3635 2.28467L31.5356 6.11255Z" fill="white" />
        <path d="M8.46146 33.9076L6.19482 31.641L2.36694 35.4688L4.63358 37.7355L8.46146 33.9076Z" fill="white" />
        <path d="M35.3664 37.7259L37.6331 35.4593L33.8052 31.6314L31.5385 33.898L35.3664 37.7259Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3612_981">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import React from "react"

import { getIconSize } from "utils/get-icon-size"

export function ArrowDown({
  w = "16",
  h = "16",
  className,
}: {
  h?: string | number
  w?: string | number
  className?: string
}) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-[${getIconSize(w)}] h-[${getIconSize(h)}] ${className}`}
    >
      <path
        d="M7.57681 11.7877C7.7906 12.0708 8.2094 12.0708 8.42319 11.7877L12.8881 5.87631C13.1585 5.5184 12.9081 5 12.465 5H3.53504C3.09186 5 2.84151 5.5184 3.11185 5.87631L7.57681 11.7877Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_3818_2367"
          x="3"
          y="5"
          width="10"
          height="9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3818_2367" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3818_2367" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

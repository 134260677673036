import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type FrozenReward = {
  amount: number
  available_at: string
  id: string
  inserted_at: string
  token_id: string | null
  type: "OIL" | "BIT" | "POWER_UP" | "MOD"
  updated_at: string
}

export type GetFrozenRewardsResponseDataType = Array<FrozenReward>

export const getFrozenRewards = async (token: string) => {
  const res = await axios.get<GetFrozenRewardsResponseDataType>(`${getApiBaseUrl()}/rewards/frozen`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

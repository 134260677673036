import { useContext, useEffect } from "react"

import type { AuthContextType } from "../../context/auth"
import { AuthContext } from "../../context/auth"

export function useAuthToken(authToken?: string | null) {
  const auth = useContext<AuthContextType | null>(AuthContext)

  useEffect(() => {
    if (auth && authToken && !auth.token) {
      auth.login(authToken)
    }
  }, [auth, authToken])

  return auth
}

import axios from "axios"

import type { SignForRemoveUnit } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type getSignatureForRemoveUnitResponseData = SignForRemoveUnit

export const getSignatureForRemoveUnit = async (token: string, unitId: number) => {
  const res = await axios.post<getSignatureForRemoveUnitResponseData>(
    `${getApiBaseUrl()}/rent_pools/remove_unit`,
    {
      bot_id: unitId,
    },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

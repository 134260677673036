import React from "react"

export function Discord({
  fill = "white",
  h = 28,
  w = 28,
  className = "",
}: {
  fill?: string
  h?: number
  w?: number
  className?: string
}) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 30 30" className={className}>
      <g>
        <path
          fill={fill}
          d="M15 0C7.26801 0 1 6.26801 1 14C1 21.732 7.26801 28 15 28C22.732 28 29 21.732 29 14C29 6.26801 22.732 0 15 0ZM9.69467 6.06667H20.772C21.7061 6.06667 22.4667 6.818 22.4667 7.749V22.4L20.6893 20.8483L19.6891 19.9337L18.6309 18.9618L19.0691 20.4727H9.69467C8.76053 20.4727 8 19.7213 8 18.7903V7.749C8 6.818 8.76053 6.06667 9.69467 6.06667Z"
        />
        <path
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5811 16.7324C17.5811 16.7324 17.2835 16.3812 17.0355 16.0709C18.1184 15.7687 18.5317 15.099 18.5317 15.099C18.1928 15.3195 17.8704 15.4747 17.5811 15.5809C17.1677 15.7524 16.7709 15.8667 16.3824 15.932C15.5888 16.079 14.8613 16.0382 14.2413 15.9239C13.7701 15.834 13.3651 15.7034 13.0261 15.5727C12.836 15.4992 12.6293 15.4094 12.4227 15.295C12.4103 15.2869 12.3979 15.2808 12.3855 15.2746C12.3731 15.2685 12.3607 15.2624 12.3483 15.2542C12.3317 15.246 12.3235 15.2379 12.3152 15.2297C12.1664 15.148 12.0837 15.0909 12.0837 15.0909C12.0837 15.0909 12.4805 15.7442 13.5304 16.0545C13.2824 16.3649 12.9765 16.7324 12.9765 16.7324C11.1496 16.6752 10.4552 15.491 10.4552 15.491C10.4552 12.8614 11.6456 10.7299 11.6456 10.7299C12.836 9.84788 13.9685 9.87238 13.9685 9.87238L14.0512 9.97038C12.5632 10.395 11.8771 11.0402 11.8771 11.0402C11.8771 11.0402 12.0589 10.9422 12.3648 10.8034C13.2493 10.4195 13.952 10.3134 14.2413 10.2889L14.2599 10.2858C14.3016 10.2788 14.3386 10.2725 14.3819 10.2725C14.8861 10.2072 15.4565 10.1909 16.0517 10.2562C16.8371 10.346 17.6803 10.5747 18.54 11.0402C18.54 11.0402 17.8869 10.4277 16.4816 10.003L16.5973 9.87238C16.5973 9.87238 17.7299 9.84788 18.9203 10.7299C18.9203 10.7299 20.1107 12.8614 20.1107 15.491C20.1107 15.491 19.408 16.6752 17.5811 16.7324ZM13.9699 13.0667C13.5024 13.0667 13.1333 13.4871 13.1333 14C13.1333 14.513 13.5106 14.9334 13.9699 14.9334C14.4374 14.9334 14.8064 14.513 14.8064 14C14.8146 13.4871 14.4374 13.0667 13.9699 13.0667ZM16.1269 14C16.1269 13.4871 16.496 13.0667 16.9634 13.0667C17.4309 13.0667 17.8 13.4871 17.8 14C17.8 14.513 17.4309 14.9334 16.9634 14.9334C16.5042 14.9334 16.1269 14.513 16.1269 14Z"
        />
      </g>
    </svg>
  )
}

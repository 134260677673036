import axios from "axios"

import type { SignedSpend } from "types/reward"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type SignSpendResponseData = SignedSpend

export const signSpend = async (token: string, spendId: string, oilAmount: number, bitsAmount: number) => {
  const additionalAmounts: { BIT?: number; OIL?: number } = {}

  if (oilAmount) {
    additionalAmounts.OIL = oilAmount
  }

  if (bitsAmount) {
    additionalAmounts.BIT = bitsAmount
  }

  const res = await axios.post<SignSpendResponseData>(
    `${getApiBaseUrl()}/rewards/early/${spendId}/sign`,
    {
      additional_amounts: additionalAmounts,
    },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetEggResponseData = {
  egg: {
    reveal_at: string
    ships: {
      defender: number
      destroyer: number
      support: number
    }
  }
}

export const getEgg = async (token: string) => {
  const res = await axios.get<GetEggResponseData>(`${getApiBaseUrl()}/ships/egg`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

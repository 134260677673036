import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  message: string
  nonce: string
  signature: string
}

type ResponseData = {
  token: string
}

export const loginWithWallet = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/auth/wallet`, regBody)
  return returnIf200(res)
}

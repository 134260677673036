import axios from "axios"

import type { AllianceApplication } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../../utils"

type GetMyApplicationsResponseData = Array<AllianceApplication>

export const getMyApplications = async (token: string) => {
  const res = await axios.get<GetMyApplicationsResponseData>(`${getApiBaseUrl()}/my/alliance/applications/`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

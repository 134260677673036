import axios from "axios"

import type { Pool } from "types/pool"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetPoolResponseData = Pool

export const getPool = async (token: string, id: string) => {
  const res = await axios.get<GetPoolResponseData>(`${getApiBaseUrl()}/rent_pools/${id}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })

  return returnIf200(res)
}

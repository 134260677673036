import axios from "axios"

import type { PageableShips } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetShipsByAccountProps = PageableShips

export const getShipsByAccount = async (id: string, queryParams = "") => {
  const res = await axios.get<GetShipsByAccountProps>(`${getApiBaseUrl()}/ships/by_account/${id}${queryParams}`, {})
  return returnIf200(res)
}

import axios from "axios"
import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  email: string
  password: string
}

type ResponseData = {
  token: string
}

export const loginWithEmail = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/auth`, regBody)
  return returnIf200(res)
}

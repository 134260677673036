import { approveApplication } from "./approve-application"
import { createApplication } from "./create-application"
import { declineApplication } from "./decline-application"
import { deleteApplication } from "./delete-application"
import { getMyApplications } from "./get-my-applications"
import { getPoolApplications } from "./get-pool-applications"
import { getSignatureForRemoveUnit } from "./get-signature-for-remove-unit"
import { revokeApplicationFromPool } from "./revoke-application-from-pool"

const applications = {
  createApplication,
  approveApplication,
  declineApplication,
  deleteApplication,
  getMyApplications,
  getPoolApplications,
  revokeApplicationFromPool,
  getSignatureForRemoveUnit,
}

export default applications

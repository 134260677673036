import axios from "axios"

import type { Bot } from "types/bot"

import { getApiBaseUrl, returnIf200 } from "../utils"

export const demoRepairBot = async (token: string, tokenId: number) => {
  const res = await axios.post<Bot>(`${getApiBaseUrl()}/free_pools/repair/bot/${tokenId}`, null, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import axios from "axios"

import type { Alliance } from "types/alliance"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type UpdateAllianceResponseData = Alliance
export type UpdateAllianceRequestData = {
  name?: string
  tag?: string
  info?: string
  discord_link?: string
}

export const updateAlliance = async (token: string, allianceId: string, data: UpdateAllianceRequestData) => {
  const res = await axios.put<UpdateAllianceResponseData>(
    `${getApiBaseUrl()}/admin/alliances/${allianceId}`,
    { alliance: data },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

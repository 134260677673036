import { useMounted } from "components/hooks/use-mounted"
import { Box } from "components/shared/box"
import { Button } from "components/shared/landing/button"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"
import Image from "next/image"
import { useState } from "react"
import { useCookies } from "react-cookie"
import { day, year } from "utils/time"
import CookieSvg from "/public/images/modals/cookies/cookie.svg"

const COOKIE_NAME = "accept_cookies"

export function CookiesPopupSpawn() {
  const [cookies, setCookie] = useCookies([COOKIE_NAME])
  const [hasCookie, setHasCookie] = useState(Boolean(cookies[COOKIE_NAME]))

  const mounted = useMounted()

  if (hasCookie || !mounted) {
    return null
  }

  const handleClick = (accept: boolean) => () => {
    setCookie(COOKIE_NAME, accept ? 1 : 0, { maxAge: accept ? year : day })
    setHasCookie(true)
  }

  return (
    <Box row className="fixed left-0 bottom-0 w-full md:max-w-[60vw] z-40 p-6 ">
      <Box
        full
        center
        className="gap-6 backdrop-blur-md bg-black/40 p-6 rounded-md border-[1px] border-gray-200/20 drop-shadow flex-col md:flex-row"
      >
        <Box row center className="gap-6">
          <Box className="w-[46px] h-[46px] min-w-[46px] min-h-[46px]">
            <Image src={CookieSvg} alt="cookie" />
          </Box>
          <Text>
            We use third-party cookies to personalize content, ads, and analyze site traffic.
            <br />
            <Link href="/cookies" target="_blank" className="text-soft-space">
              Learn more
            </Link>
          </Text>
        </Box>
        <Box row className="gap-6 h-fit">
          <Button noOverlay type="secondary" onClick={handleClick(false)} className="text-white">
            Decline
          </Button>
          <Button noOverlay onClick={handleClick(true)} className="text-white">
            Okay
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

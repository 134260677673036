import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"
import type { MarketplaceBotInfo } from "./domain"

export const getMarketplaceShip = async (shipId: number): Promise<MarketplaceBotInfo | null> => {
  try {
    const res = await axios.get<MarketplaceBotInfo>(`${getApiBaseUrl()}/marketplace/ship/${shipId}`)
    return returnIf200(res)
  } catch (error) {
    return null
  }
}

import React from "react"
import { trackFooterClick, trackFooterSocialClick } from "services/analytics"

import { Links } from "config/links"

import { Box } from "./shared/box"
import { Discord } from "./shared/icons/discord"
import { Facebook } from "./shared/icons/facebook"
import { Instagram } from "./shared/icons/instagram"
import { LinkedIn } from "./shared/icons/linkedin"
import { Medium } from "./shared/icons/medium"
import { OpenSea } from "./shared/icons/opensea"
import { Telegram } from "./shared/icons/telegram"
import { Twitter } from "./shared/icons/twitter"
import { Button } from "./shared/landing/button"
import { Link } from "./shared/link"
import { LogoTextUrl } from "./shared/logo-text-url"
import { Text } from "./shared/text"

const links = [
  { label: "Companions", url: Links.COMPANION, target: "_blank", name: "click_companion" },
  { label: "Privacy", url: Links.PRIVACY, name: "click_privacy" },
  { label: "Disclamer", url: Links.DISCLAIMER, name: "click_disclamer" },
  { label: "Cookies", url: Links.COOKIES, name: "click_cookies" },
  { label: "Contact us", url: Links.CONTACT, name: "contacts" },
]

// @todo: common place for links
const btns = [
  { content: <Discord />, href: Links.DISCORD, name: "discord" },
  { content: <Twitter />, href: Links.TWITTER, name: "twitter" },
  { content: <Telegram />, href: Links.TELEGRAM, name: "telegram" },
  { content: <Instagram />, href: Links.INSTAGRAM, name: "instagram" },
  { content: <Facebook />, href: Links.FB, name: "facebook" },
  { content: <LinkedIn />, href: Links.LINKEDIN, name: "linkedin" },
  { content: <Medium />, href: Links.MEDIUM, name: "medium" },
  { content: <OpenSea />, href: Links.OPENSEA_GENESIS, name: "opensea" },
]

type FooterProps = {
  maxWidth?: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Footer({ maxWidth = 1000 }: FooterProps) {
  return (
    <Box center className="bg-[#010020] min-h-[200px] w-full text-white z-1">
      <Box full className="max-w-[1200px] items-center px-4 md:px-12 lg:px-6">
        <Box className="flex-1 w-full justify-center md:mt-12 md:flex-row items-center  md:justify-between">
          <Box row className="justify-center md:justify-start my-8 md:my-0">
            <LogoTextUrl place="footer" />
          </Box>
          <Box
            row
            className="justify-between md:justify-normal w-full md:w-auto max-w-[360px] md:max-w-none mt-2 gap-2 md:gap-6 md:mt-0 flex-col md:flex-row items-center"
          >
            {links.map(l => (
              <Link
                key={l.label}
                href={l.url}
                className="text-sm hover:text-white"
                withRedirect
                target={l.target === "_blank" ? "_blank" : "_self"}
                onClick={() =>
                  trackFooterClick(
                    l.name as "contacts" | "click_privacy" | "click_disclamer" | "click_cookies" | "click_companion",
                  )
                }
              >
                {l.label}
              </Link>
            ))}
          </Box>
        </Box>
        <Box center className="flex-1 mt-10 mb-8 md:flex-row md:mt-3 md:w-full md:justify-between">
          <div className="text-center mx-5 md:text-left md:mx-0">
            <Text className="opacity-80 text-sm">Also, you can contact us via social networks!</Text>
          </div>
          <div className="mt-9 grid grid-rows-2 grid-cols-4 gap-6 md:flex md:flex-row md:gap-2 md:mt-0">
            {btns.map((b, i) => (
              <Link href={b.href} key={i} target="_blank" withRedirect onClick={() => trackFooterSocialClick(b.name)}>
                <Button type="secondary" noPadding noOverlay className={`h-[42px] w-[42px]`}>
                  {b.content}
                </Button>
              </Link>
            ))}
          </div>
        </Box>
      </Box>
    </Box>
  )
}

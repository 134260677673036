import React from "react"

export function LinkedIn({
  fill = "white",
  h = 28,
  w = 28,
  className = "",
}: {
  fill?: string
  h?: number
  w?: number
  className?: string
}) {
  return (
    <svg version="1.1" width={w} height={h} x="0px" y="0px" viewBox="0 0 22 22" className={className}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.231 0.230469C5.70811 0.230469 1.23096 4.70762 1.23096 10.2305C1.23096 15.7533 5.70811 20.2305 11.231 20.2305C16.7538 20.2305 21.231 15.7533 21.231 10.2305C21.231 4.70762 16.7538 0.230469 11.231 0.230469ZM6.03163 8.51291H8.29785V15.322H6.03163V8.51291ZM8.44711 6.40659C8.4324 5.73897 7.95498 5.23047 7.17972 5.23047C6.40446 5.23047 5.89762 5.73897 5.89762 6.40659C5.89762 7.06039 6.38948 7.58353 7.1503 7.58353H7.16478C7.95498 7.58353 8.44711 7.06039 8.44711 6.40659ZM13.8614 8.35302C15.3527 8.35302 16.4707 9.32641 16.4707 11.4179L16.4706 15.322H14.2045V11.6791C14.2045 10.7641 13.8765 10.1397 13.0562 10.1397C12.4301 10.1397 12.0572 10.5606 11.8934 10.9672C11.8334 11.1129 11.8187 11.3159 11.8187 11.5194V15.3221H9.55225C9.55225 15.3221 9.58212 9.15201 9.55225 8.51309H11.8187V9.47753C12.1195 9.0139 12.6582 8.35302 13.8614 8.35302Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

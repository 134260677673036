import { useEffect, useRef, useState } from "react"

import { isBrowser } from "utils/browser/is-browser"

export enum LocalStorageKey {
  LAST_CONNECTION = "LAST_CONNECTION",
}

export const useLocalStorage = <T = string>(
  key: string,
  initialValue: T | null = null,
): [state: T | null, setValue: (value: T | null) => void] => {
  const initializer = useRef((key: string): T | null => {
    if (!isBrowser) {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }
      window.localStorage.setItem(key, JSON.stringify(initialValue))
      return initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const [state, setState] = useState<T | null>(initializer.current(key))

  useEffect(() => setState(initializer.current(key)), [key])

  const setValue = (value: T | null) => {
    try {
      setState(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  }
  return [state, setValue]
}

import axios from "axios"

import type { Spend } from "types/reward"

import { getApiBaseUrl, returnIf200 } from "../../utils"

export type CreateSpendData = Spend

export const createSpend = async (token: string, contract: string, oilsAmount: number, bitsAmount: number) => {
  const parts = []

  if (oilsAmount > 0) {
    parts.push({
      amount: oilsAmount,
      type: "OIL",
    })
  }

  if (bitsAmount) {
    parts.push({
      amount: bitsAmount,
      type: "BIT",
    })
  }

  const res = await axios.post<CreateSpendData>(
    `${getApiBaseUrl()}/rewards/early`,
    {
      contract,
      request: parts,
    },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    },
  )
  return returnIf200(res)
}

import axios from "axios"
import type { Alliance } from "types/alliance"
import { getApiBaseUrl, returnIf200 } from "../utils"

export type GetAllianceResponseData = Alliance

export const getAlliance = async (allianceId: string) => {
  const res = await axios.get<GetAllianceResponseData>(`${getApiBaseUrl()}/alliances/${allianceId}`)
  return returnIf200(res)
}

import type { Chain } from "@usedapp/core"
import { ChainId } from "@usedapp/core"
import { useConfig } from "@usedapp/core"

import { useWeb3Simple } from "./use-base"

const getAddNetworkParams = (chain: Chain) => ({
  chainId: `0x${chain.chainId.toString(16)}`,
  chainName: chain.chainName,
  rpcUrls: [chain.rpcUrl],
  blockExplorerUrls: chain.blockExplorerUrl ? [chain.blockExplorerUrl] : undefined,
  nativeCurrency: chain.nativeCurrency,
})

const getRpcUrl = (chain: Chain) => {
  switch (chain.chainId) {
    case ChainId.Mumbai:
      return "https://matic-mumbai.chainstacklabs.com/"
    case ChainId.Polygon:
      return "https://polygon-rpc.com/"
    default:
      return
  }
}

const getNativeCurrency = (chain: Chain) => {
  switch (chain.chainId) {
    case ChainId.Mumbai:
    case ChainId.Polygon:
      return {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      }
    default:
      return
  }
}

export default function useSwitchNetwork() {
  const { library } = useWeb3Simple()
  const config = useConfig()

  const switchNetwork = async (chainId: number) => {
    if (!library) {
      throw new Error("Provider not connected.")
    }

    try {
      await library.send("wallet_switchEthereumChain", [{ chainId: `0x${chainId.toString(16)}` }])
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message.includes("Try adding")) {
        const chain = config.networks?.find(chain => chain.chainId === chainId)

        if (chain) {
          if (!chain.rpcUrl) {
            chain.rpcUrl = getRpcUrl(chain)
          }
          if (!chain.nativeCurrency) {
            chain.nativeCurrency = getNativeCurrency(chain)
          }
          if (chain.rpcUrl) {
            await library.send("wallet_addEthereumChain", [getAddNetworkParams(chain)])
          }
        }
      } else {
        throw error
      }
    }
  }

  return { switchNetwork }
}

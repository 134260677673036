import axios from "axios"

import { returnIf200 } from "../utils"

type RequestData = string

type ResponseData = unknown

export const sendBatch = async (regBody: RequestData) => {
  const res = await axios.post<ResponseData>("https://whirpool-api.cryptobots.me/", regBody, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  return returnIf200(res)
}

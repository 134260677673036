import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

type ResponseData = {
  message: string
  nonce: number
}

export const getNonce = async () => {
  const res = await axios.get<ResponseData>(`${getApiBaseUrl()}/wallet/link_wallet`)
  return returnIf200(res)
}

import axios from "axios"

import type { Account } from "types/account"

import { getApiBaseUrl, returnIf200 } from "../utils"

type RequestData = {
  message: string
  nonce: number | string
  signature: string
}

type ResponseData = Account

export const connectWallet = async (regBody: RequestData, token: string) => {
  regBody = { ...regBody, nonce: String(regBody.nonce) }
  const res = await axios.post<ResponseData>(`${getApiBaseUrl()}/wallet/link_wallet`, regBody, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return returnIf200(res)
}

import { createSpend } from "./create-spend"
import { deleteSpend } from "./delete-spend"
import { getSpend } from "./get-spend"
import { getSpends } from "./get-spends"
import { signSpend } from "./sign-spend"

const spends = {
  createSpend,
  getSpends,
  getSpend,
  signSpend,
  deleteSpend,
}

export default spends

import { getShipsByAccount } from "./by-account"
import { getEgg } from "./get-egg"
import { getMyShips } from "./get-my-ships"
import { getRevealData } from "./get-reveal-data"
import { getShip } from "./get-ship"

const ships = {
  getEgg,
  getShip,
  getMyShips,
  getShipsByAccount,
  getRevealData,
}

export default ships

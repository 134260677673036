import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type LeaderboardAlliance = {
  name: string
  owner_icon: string
  owner_id: string
  owner_nickname: string
  icon: string
  id: string
  points: number
}

export type GetTopAlliancesData = {
  data: LeaderboardAlliance[]
}

export const getTopAlliances = async () => {
  const res = await axios.get<GetTopAlliancesData>(`${getApiBaseUrl()}/leaderboard/testnet/alliances`)
  return returnIf200(res)
}

import axios from "axios"

import { getApiBaseUrl, returnIf200 } from "../utils"

export type RevealResponseData = {
  message: {
    id: string
    seed: string
  }
  signature: string
}

export const getRevealData = async (botId: number, token: string) => {
  const res = await axios.get<RevealResponseData>(`${getApiBaseUrl()}/bot/${botId}/reveal`, {
    headers: {
      authorization: "Bearer " + token,
    },
  })
  return returnIf200(res)
}

import { useCallback, useEffect, useState } from "react"
import { api } from "services/api"
import type { ClientSettings } from "services/api/settings/get-settings"

export type ClientSettingsExtended = ClientSettings & {
  total_value_claimed: number
}
export const defaultSettings: ClientSettingsExtended = {
  apy_vl: 0,
  apy_best_player: 0,
  apy_lp_staking_bits: 0,
  apy_lp_staking_oil: 0,
  mp_show_fee: 0,
  polygon_blocks_per_second: 0,
  bits_usd_course: 0,
  oil_usd_course: 0,
  matic_usd_course: 0,
  total_claimed_bits: 0,
  total_claimed_matic: 0,
  total_value_claimed: 0,
}

export function useClientSettings() {
  const [settings, setSettings] = useState<ClientSettingsExtended>(defaultSettings)

  const updateSettings = useCallback(async () => {
    try {
      const _settings = await api.settings.getSettings()
      _settings &&
        setSettings({
          ..._settings,
          total_value_claimed:
            _settings.total_claimed_bits * _settings.bits_usd_course +
            _settings.total_claimed_matic * _settings.matic_usd_course,
        })
    } catch (error) {}
  }, [])

  useEffect(() => {
    updateSettings()
  }, [updateSettings])

  return { ...settings, updateSettings }
}

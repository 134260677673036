import type { AmplitudeClient } from "amplitude-js"
import amplitude from "amplitude-js"

import type { EventMap } from "./events"

class AmplitudeService {
  userId?: string
  instance: AmplitudeClient

  constructor(token: string) {
    this.instance = amplitude.getInstance()
    this.instance.init(token)
  }

  setUserId = (userId: string) => {
    this.instance.setUserId(userId)
  }

  logEvent = <K extends keyof EventMap>(event: K, value: EventMap[K]) => {
    this.instance.logEvent(event as string, value)
  }
}

export const amplitudeService = new AmplitudeService("f5e35d730fdc5945c00cf625a14bde4b")

import React from "react"

import { Footer } from "../footer"
import { Header } from "../header"
import { Box } from "../shared/box"
import { HtmlHead } from "./head"

type LayoutProps = {
  children: React.ReactNode
  htmlHead?: React.ReactNode
  showHeader?: boolean
  showFooter?: boolean
}

export const maxWidth = 1200

export function Layout({ children, htmlHead = <HtmlHead />, showHeader = true, showFooter = true }: LayoutProps) {
  return (
    <>
      {htmlHead}
      <Box center className="min-h-[100vh] h-fit relative">
        <Box full className="bg-space absolute top-0 left-0 -z-[2]" />
        <Box full className="flex-1">
          {showHeader && <Header />}
          {children}
        </Box>
        {showFooter && <Footer maxWidth={maxWidth} />}
      </Box>
    </>
  )
}

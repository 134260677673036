import axios from "axios"
import { getApiBaseUrl, returnIf200 } from "../utils"

type ContractNameType =
  | "BotRevealer"
  | "Marketplace"
  | "Bit"
  | "Bot"
  | "BotMetadata"
  | "BotMinter"
  | "AllianceStaking"
  | "Oil"
export type GetContractsResponse = Array<{ name: ContractNameType; address: string }>

export const getContracts = async (): Promise<GetContractsResponse | null> => {
  const res = await axios.get<GetContractsResponse>(`${getApiBaseUrl()}/contracts`)
  return returnIf200(res)
}

import React from "react"

export function Close3({
  fill = "white",
  h = 24,
  w = 24,
  className = "",
}: {
  fill?: string
  h?: number
  w?: number
  className?: string
}) {
  return (
    <svg width={w} height={h} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6466_11176)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.242 5.36329C16.8278 4.7775 17.7775 4.7775 18.3633 5.36329C18.9491 5.94907 18.9491 6.89882 18.3633 7.48461L15.4462 10.4017C15.104 10.7439 14.9941 11.2436 14.994 11.7276C14.9939 12.2119 15.1037 12.7121 15.4462 13.0546L18.3631 15.9715C18.9488 16.5572 18.9488 17.507 18.3631 18.0928C17.7773 18.6786 16.8275 18.6786 16.2417 18.0928L13.329 15.1801C12.9852 14.8362 12.4828 14.7265 11.9965 14.7269C11.5114 14.7273 11.0105 14.8374 10.6675 15.1804L7.75669 18.0912C7.1709 18.677 6.22115 18.677 5.63537 18.0912C5.04958 17.5054 5.04958 16.5557 5.63537 15.9699L8.53869 13.0666C8.88416 12.7211 8.99415 12.2162 8.99403 11.7276C8.99392 11.2394 8.88384 10.7349 8.5386 10.3896L5.63514 7.48618C5.04935 6.90039 5.04935 5.95065 5.63514 5.36486C6.22093 4.77907 7.17068 4.77907 7.75646 5.36486L10.6641 8.27252C11.008 8.61638 11.5102 8.72651 11.9965 8.72691C12.484 8.72731 12.9877 8.61752 13.3324 8.27284L16.242 5.36329Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6466_11176"
          x="0"
          y="0"
          width="24"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6466_11176" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6466_11176" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

import { useEthers } from "@usedapp/core"
import { utils } from "ethers"
import { useCallback, useMemo } from "react"
import { useCookies } from "react-cookie"

import { year } from "utils/time"

import { useAuthToken } from "../use-auth-token"
import { useWeb3Simple } from "./use-base"
import { useNativeWalletProvider } from "./use-native-wallet-provider"

export const CONNECTION_TYPE_KEY = "CONNECTION_TYPE"
export enum WALLET_CONNECTION_TYPES {
  NATIVE = "native",
  EXTERNAL = "external",
}

type WalletOptions = {
  connectionType?: WALLET_CONNECTION_TYPES
}

export function useWallet(options?: WalletOptions) {
  const auth = useAuthToken()
  const native = useNativeWalletProvider()
  const external = useWeb3Simple()
  const { library } = useEthers()

  const [cookie, setCookie] = useCookies([CONNECTION_TYPE_KEY])

  const linkedWalletAddress = useMemo(() => {
    return auth?.account?.wallet?.toLowerCase()
  }, [auth?.account?.wallet])

  const connectionType = useMemo((): WALLET_CONNECTION_TYPES => {
    if (options?.connectionType) {
      return options.connectionType
    }

    if (cookie.CONNECTION_TYPE) {
      return cookie.CONNECTION_TYPE
    }

    return WALLET_CONNECTION_TYPES.NATIVE
  }, [options, cookie.CONNECTION_TYPE])

  const setConnectionType = useCallback(
    (connectionType: WALLET_CONNECTION_TYPES) => {
      setCookie(CONNECTION_TYPE_KEY, connectionType, {
        path: "/",
        maxAge: year / 1000,
      })
    },
    [setCookie],
  )

  const account = useMemo(() => {
    if (connectionType === WALLET_CONNECTION_TYPES.NATIVE) {
      return native.account
    } else {
      return external.account
    }
  }, [connectionType, external.account, native.account])

  const signer = useMemo(() => {
    if (connectionType === WALLET_CONNECTION_TYPES.NATIVE) {
      return native.signer
    } else if (external.account) {
      return external.library?.getSigner()
    }
  }, [connectionType, external.account, external.library, native.signer])

  const connected = useMemo(() => {
    return !!account
  }, [account])

  const checkPrivateKey = useCallback(() => {
    if (connectionType === WALLET_CONNECTION_TYPES.NATIVE) {
      if (linkedWalletAddress && linkedWalletAddress === native.account) {
        return native.checkPrivateKey(native.account)
      }

      return false
    } else {
      return linkedWalletAddress && linkedWalletAddress === external.account
    }
  }, [connectionType, external.account, linkedWalletAddress, native])

  const readProvider = useMemo(() => {
    return library
  }, [library])

  const addressIsValid = useCallback((address: string) => {
    return utils.isAddress(address)
  }, [])

  return {
    account,
    connected,
    signer,
    connectionType: connectionType,
    setConnectionType,
    checkPrivateKey,
    readProvider,
    addressIsValid,
  }
}

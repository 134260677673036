import axios from "axios"

import type { Pageable } from "types/api"

import { getApiBaseUrl, returnIf200 } from "../utils"
import type { MarketplaceShipInfo } from "./domain"

export type GetMarketplaceShipsResponse = Pageable<MarketplaceShipInfo[]>

export const getMarketplaceShips = async (queryParams = ""): Promise<GetMarketplaceShipsResponse | null> => {
  const res = await axios.get<GetMarketplaceShipsResponse>(`${getApiBaseUrl()}/marketplace/ships${queryParams}`)
  return returnIf200(res)
}
